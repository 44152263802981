import {AfterViewInit, Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {AppAdminComponent} from "../admin/app-admin/app-admin.component";
import { DataService } from "../data.service";
import {APP_BASE_HREF} from "@angular/common";

@Component({
  selector: 'app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent implements OnInit, AfterViewInit {
  public display: any;
  public content: any;
  public bannerpath: any;
  public bannertopic: any;

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.dataService.thiscomponentdisplay.subscribe(display => this.display = display);
    this.dataService.thiscontent.subscribe(content => this.content = content);
    this.dataService.thistopic.subscribe(topic => this.bannertopic = topic);
  }

  ngAfterViewInit() {
    //console.log('component banner started');
  }

}
