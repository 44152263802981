import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ErrorStateMatcher} from "@angular/material/core";
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {DataService} from "../../data.service";
import {HttpClient} from "@angular/common/http";
import {Helpers} from "../../app.helpers";
import {AdminLogin, ErrorMSG} from "../../app.models";
import {Md5} from 'ts-md5/dist/md5';
import {ActivatedRoute, Router} from "@angular/router";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-app-adminlogin',
  templateUrl: './app-adminlogin.component.html',
  styleUrls: ['./app-adminlogin.component.scss']
})
export class AppAdminloginComponent implements OnInit, AfterViewInit {
  public login: AdminLogin;
  public error = this.dataService.thiserror.subscribe(error => this.error = error);

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.min(5),
  ]);

  constructor(
      public dataService: DataService,
      private httpClient: HttpClient,
      public helpers: Helpers,
      private router: Router,
      private route: ActivatedRoute
  ) { }

  ngAfterViewInit(): void {
    this.login = this.helpers.login();
  }


  ngOnInit(): void {
    this.login = this.helpers.login();
    this.dataService.thiserror.subscribe(error => this.error = error);
    let display = {displayparent: false, displaylinkbar: false, textbanner: false, displaybanner: false, margintopfix: false};
    this.dataService.updateDisplay(display);
    let getparams = {};
  }

  public updateValue(event:any,field:string) {
    this.login[field] = event;
  }

  public logIn() {
    const MD5 = new Md5();
    this.error = this.helpers.error();
    if(this.login.user == '' || this.login.password == '') {
      this.error.status = true;
      this.error.msg = 'Please, fill the fields email and password';
    }
    else {
      const data = {user:this.login.user,password: MD5.appendStr(this.login.password).end()};
      this.dataService.requestLogin('auth',data).subscribe(data => {
        if(data.code == 'OK') {
          this.dataService.setAdmin({'lg':data.lg,admin:1});
          sessionStorage.keylogin = data.sessionkey;
          sessionStorage.lg = data.lg;
          this.dataService.setLanguage(sessionStorage.lg);
          this.dataService.updateTopBar(this.dataService.getTexts('topbar','texts'));
          this.dataService.updateMenu(this.dataService.getTexts('menu','texts'));
          this.dataService.updateSubMenu(this.dataService.getTexts('menu','submenu'));
          this.dataService.updateFooter(this.dataService.getTexts('footer','texts'));
          this.dataService.updateMiddleWindows(this.dataService.getTexts('middlewindows','texts'),'index');
          this.dataService.updateAdminTexts(this.dataService.getTexts('index','texts',null,sessionStorage.lg));
          this.router.navigate(['./admin']);
        }
        else {
          this.error.status = true;
          this.error.msg = 'Email or password is invalid. Not authorized to login.'
        }
      },error => {
        this.error.status = true;
        this.error.msg = 'Email or password is invalid. Not authorized to login.'
      });
    }
  }
}
