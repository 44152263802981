<app-linkbar
        [linkmenu]="linkmenu"
        [showlink]="showlink"
        [childcomponent]="childcomponent"
></app-linkbar>
<div id="contentcustomize" class="container-fluid">
    <div id="setup" [@openClose] *ngIf="page==1">
        <div style="" class="alert alert-message-full" *ngIf="conerror.status" [innerHTML]="conerror.msg"></div>
        <div class="form-row">
            <h4 style="margin-bottom: 30px">{{contenttexts.title}}</h4>
        </div>
        <div class="form-row" style="margin-bottom: 30px; padding: 5px">
            <div class="col-4 layout4col" *ngIf="this.dclist">
                <h5 style="width: 100%">{{contenttexts.serverlocation.text}}</h5>
                <mat-form-field>
                    <mat-label>{{contenttexts.serverlocation.comboboxtext}}</mat-label>
                    <mat-select [(ngModel)]="order.dcid" (selectionChange)="getProducts(); getOrderPrice()">
                        <mat-option *ngIf="this.dclist.length == 0">Loading...</mat-option>
                        <mat-option *ngIf="this.dclist.length > 0">None</mat-option>
                        <mat-optgroup *ngFor="let group of this.dclist | keyvalue" [label]="group.key">
                            <mat-option *ngFor="let lc of this.dclist[group.key]" [value]="lc.code">{{lc.dcname}}</mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4 system" *ngIf="menuopt=='cloudservers'">
                <h5 style="width: 100%">{{contenttexts.os.text}}</h5>
                <mat-form-field>
                    <mat-label>{{contenttexts.os.comboboxtext}}</mat-label>
                    <mat-select [(ngModel)]="order.os" (selectionChange)="getOrderPrice()">
                        <mat-option *ngIf="this.itemsorder.os.length == 0 && this.order.dcid != 0">Loading...</mat-option>
                        <mat-option *ngIf="this.itemsorder.os.length > 0 || this.order.dcid == 0">None</mat-option>
                        <mat-option *ngFor="let os of this.itemsorder.os" [value]="os.id">{{os.id}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4 price" id="infoprice">
                <h5>{{contenttexts.textprice}}
                    <button style="font-size: 14px !important;" class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{this.currency}}
                    </button></h5>
                <span>$ {{orderprice | number:'1.2-2' }}</span>
            </div>
        </div>
        <div class="form-group row" id="customizeoptions">
            <ng-container *ngFor="let h of serviceopt">
            <div class="col layout3col text-center" *ngIf="h.name != 'os'">
                <h4 style="margin-top: 0px">
                    <span
                            class="badge badge-primary font-weight-normal"
                            style="display: inline-block; width: 80%"
                    >{{h.text}}</span>
                </h4>
                <mat-form-field style="width: 150px">
                    <mat-label>{{h.text}}</mat-label>
                    <mat-select [(ngModel)]="order[h.name]" (selectionChange)="getOrderPrice()">
                        <mat-option>None</mat-option>
                        <mat-option
                                *ngFor="let it of this.itemsorder[h.name]"
                                [value]="it.id">
                            {{it.id}} - {{helpers.getUnit(h.name)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            </ng-container>
        </div>
        <div class="form-group row">
            <div style="width: 150px; margin-left: 20px; margin-top: 15px">
                <div class="btn-leftcol2" (click)="nextOrder()">{{contenttexts.nextbtn}}</div>
                <div class="btn-rightcol2" (click)="nextOrder()" style="padding: 7px !important;"><img src="assets/img/right-arrow.png"></div>
            </div>
            <div class="alert alert-warning" style="margin: 15px" *ngIf="error.status">{{error.msg}}</div>
        </div>
    </div>
    <div id="setupinfo" [@openClose] *ngIf="page==2">
        <div class="form-row" id="divsetupinfo">
                <div class="card" style="width: 100%;">
                    <div class="card-header">
                        {{vieworder.title}}
                    </div>
                    <div class="col">
                    <div class="infoicon">
                        <img src="assets/img/cloud_servers.png" width="70%">
                    </div>
                    <div class="infodata">
                        <div style="width: 100%" *ngFor="let it of objectKeys(order)">
                            <ul *ngIf="it != 'price'">
                                <li>
                                    <span style="width: 100px !important;" *ngIf="hidekey.indexOf(it) == -1">{{helpers.search(serviceopt,it,'name')['text'] | uppercase }}: </span>
                                    <span style="width: calc(100% - 100px) !important;" *ngIf="hidekey.indexOf(it) == -1">{{order[it]+' '+helpers.getUnit(it)}}</span>
                                    <span style="width: 100px !important;" *ngIf="it == 'dcid'">LOCATION: </span>
                                    <span style="width: calc(100% - 100px) !important;" *ngIf="it == 'dcid'">{{ helpers.getDCName(dclist,this.order.dcid) }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
        <div class="form-row" id="divsetupinfo">
            <div class="col">
                <div class="infoicon">
                    <div style="width: 150px;">
                        <div class="btn-leftcol2" (click)="page=dataservice.backPageComponent(url,'texts')">{{vieworder.btntxt}}</div>
                        <div class="btn-rightcol2" style="padding: 7px !important;" (click)="page=dataservice.backPageComponent(url,'texts')"><img src="assets/img/right-arrow.png"></div>
                    </div>
                </div>
                <div class="infodata" style="margin-top: 10px">
                    <span style="display: inline-block; font-size: 20px; float:left; width: 60px !important;">{{vieworder.textprice}}: </span>
                    <span style="font-size: 20px; float:left">$ {{ orderprice | number: '1.2-2' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="placeorder" [@openClose] *ngIf="page==2">
    <app-app-signup [@openClose] [order]="order"></app-app-signup>
</div>
