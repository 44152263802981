<div id="contentadmin" class="container-fluid" 
    style="position: absolute; margin-top: 50px; background-color: #EAEAEA; z-index: 10"
     xmlns="http://www.w3.org/1999/html">
    <div class="row">
        <div class="left-panel col-3" style="overflow: auto">
            <div class="sectiontext lefttopcolor">
                : : SECTIONS
                <div class="savediv">
                    <button type="button" class="btn btn-sm btn-primary" (click)="saveChanges()">
                        SAVE CHANGES
                        <div *ngIf="loading" class="spinner-border" role="status" style="float: right; width: 20px; height: 20px; margin-left: 10px">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
            <div style="margin-top: 60px">
            <mat-card (click)="setSectionChange('topbar')">Top Bar</mat-card>
            <mat-card (click)="openMenu()">Menu <span class="badge badge-primary">{{cmenu}}</span>
                <img id="arrowmenu" src="assets/img/arrow-down.svg" height="15px" width="15px" />
                <mat-list>
                    <ng-container *ngFor="let opt of menuEN.submenu | keyvalue"><mat-list-item *ngIf="opt.key != 'termsconditions' && opt.key != 'privacypolicy' && opt.key != 'keystructure'" class="menuitems" (click)="menupage=opt.key; setSectionChange(opt.key,opt.key)"> {{opt.key}} </mat-list-item></ng-container>
                </mat-list>
            </mat-card>
            <mat-card (click)="setSectionChange('menu')">Sub-Menu Options</mat-card>
            <mat-card (click)="setSectionChange('keystructure')">Messages / Alerts</mat-card>
            <mat-card (click)="setSectionChange('index')">Home</mat-card>
            <mat-card (click)="setSectionChange('middlewindows')">Middle Windows</mat-card>
            <mat-card (click)="setSectionChange('footer')">Footer</mat-card>
            <mat-card (click)="setSectionChange('signup')">Signup</mat-card>
            <mat-card (click)="setSectionChange('contact')">Contac Us</mat-card>
            <mat-card (click)="setSectionChange('termsconditions','termsconditions')">Terms & Conditions</mat-card>
            <mat-card (click)="setSectionChange('privacypolicy','privacypolicy')">Privacy Policy</mat-card>
            <mat-card (click)="setSectionChange('metadata')">Meta-data</mat-card>
            <div style="margin-top: 40px; color: #FFFFFF; font-weight: bold">
                : : SEARCH EXPRESSION
            </div>
            <div>
                <input type="text"
                       (keyup)="searchText($event.target.value)"
                       class="form-control"
                       id="keyserach"
                       aria-describedby="search"
                       placeholder="Search">
                <div style="width: 100%" *ngIf="searcherror.status">
                    <div class="alert alert-warning" style="margin-top: 15px">{{searcherror.msg}}</div>
                </div>
                <div style="width: 100%; margin-top: 15px; margin-bottom: 70px">
                <span style="color: #FFFFFF" *ngIf="helpers.getSizeObject(seachsections) == 0">NO EXPRESSION FOUND</span>
                <div style="width: 100%" *ngIf="helpers.getSizeObject(seachsections) >= 1">
                    <button class="btn btn-primary"
                            style="margin: 5px 0px 5px 10px" *ngFor="let opt of seachsections"
                            (click)="setSectionChange(opt.component,opt.page)">{{opt.opt}}
                    </button>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div class="right-panel col-9">
            <div class="sectiontext righttopcolor row" style="color:#666666; border-bottom: 2px solid #666666; float: left">
                <div class="col-3">
                : : {{section | uppercase}}
                </div>
                <div class="col-5">
                    <div class="alert alert-warning" style="margin: 0px" *ngIf="error.status">{{error.msg}}</div>
                </div>
            </div>
            <h4><span class="badge badge-primary" *ngIf="menupage!=''">Menu Options</span></h4>
            <div class="setpage form-group row" *ngIf="menupage!=''">
                <div class="col-3" *ngFor="let sub of this.menuEN.submenu | keyvalue">
                    EN -> {{this.menuEN.texts[sub.key]}}<br>
                    <input type="text" class="form-control col-12" name="{{sub.key}}" [(ngModel)]="this.menuLG.texts[sub.key]" />
                </div>
            </div>
            <form style="margin-top: 60px">
                <h4><span class="badge badge-primary">SECTIONS / FIELDS</span></h4>
                <div class="form-group row" *ngFor="let field of contentEN.fields">
                    <div class="col-12" *ngIf="checkForObject(contentEN.texts[field]) == 'object' ||
                    checkForObject(contentEN.texts[field]) == 'array'"
                    >
                        <div style="padding: 5px; border-bottom: 1px solid #666666; font-size: 18px" (click)="openSubmenuDiv(field)" id="submenu_{{field}}">
                            <span *ngIf="menupage==''" style="font-weight: bold">SECTION -> ({{field}})</span>
                            <span *ngIf="menupage!=''" style="font-weight: bold">SUBMENU -> ({{getMenuText(field)}})</span>
                        </div>

                        <div>
                            <div class="col-12" *ngFor="let col of contentEN.texts[field] | keyvalue; let icol = index">
                                <!-- if the data is an object -->
                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field]) == 'object'">
                                    <div class="col-1"></div>
                                    <div class="col-11">
                                        <div class="divstring col-10" *ngIf="checkForObject(contentEN.texts[field][col.key]) == 'string' && !helpers.searchArr(col.key,helpers.getHideKey())">
                                            <ng-container *ngIf="readonlyfields.indexOf(col.key) == -1">
                                                <span style="font-weight: bold">({{col.key}})</span>EN -> {{contentEN.texts[field][col.key]}}
                                                <textarea
                                                       type="text" class="form-control col-12"
                                                       name="{{field+'_'+col.key}}"
                                                       [(ngModel)]="contentLG.texts[field][col.key]"
                                                          rows="2"
                                                ></textarea>
                                            </ng-container>
                                        </div>
                                        <div class="optionitems col-12"
                                             *ngIf="checkForObject(contentEN.texts[field][col.key]) == 'object' ||
                                             checkForObject(contentEN.texts[field][col.key]) == 'array'"
                                        >

                                            <div style="padding: 15px 0px 15px 0px; font-size: 16px">: : Sub Section<span style="font-weight: bold"> - ({{col.key}})</span></div>
                                            <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key]) == 'array'">
                                                <div style="margin-bottom: 20px;" class="col-12" *ngFor="let opt of contentEN.texts[field][col.key] | keyvalue; let iopt = index">
                                                    -> <span>{{col.key}} ({{opt.key}})</span>
                                                    <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][opt.key]) == 'object'">
                                                        <div style="margin-bottom: 10px" class="col-12" *ngFor="let el of contentEN.texts[field][col.key][opt.key] | keyvalue; let iel = index">
                                                            <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][opt.key][el.key]) == 'object'">
                                                                -> <span>{{el.key}}</span>
                                                                <div style="margin-bottom: 10px; margin-left: 10px" class="col-12" *ngFor="let it of contentEN.texts[field][col.key][opt.key][el.key] | keyvalue">
                                                                    <ng-container *ngIf="readonlyfields.indexOf(it.key) == -1">
                                                                        ({{it.key}}) EN -> {{this.contentEN.texts[field][col.key][opt.key][el.key][it.key]}}<br>
                                                                        <textarea
                                                                               type="text"
                                                                               class="form-control col-12"
                                                                               name="{{field+el.key+iopt+iel}}"
                                                                               [(ngModel)]="this.contentLG.texts[field][col.key][opt.key][el.key][it.key]"
                                                                                  rows="2"
                                                                        ></textarea>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][opt.key][el.key]) == 'string'">

                                                                <ng-container *ngIf="readonlyfields.indexOf(el.key) == -1">
                                                                    ({{el.key}}) EN -> {{this.contentEN.texts[field][col.key][opt.key][el.key]}}<br>
                                                                    <textarea
                                                                           type="text"
                                                                           class="form-control col-12"
                                                                           name="{{field+el.key+iopt+iel}}"
                                                                           [(ngModel)]="this.contentLG.texts[field][col.key][opt.key][el.key]"
                                                                              rows="2"
                                                                    ></textarea>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="margin-bottom: 10px" class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][opt.key]) == 'string'">
                                                        <ng-container *ngIf="readonlyfields.indexOf(opt.key) == -1">
                                                            ({{opt.key}}) EN -> {{this.contentEN.texts[field][col.key][opt.key]}}<br>
                                                            <textarea
                                                                   type="text"
                                                                   class="form-control col-12"
                                                                   name="{{opt.key+iopt}}"
                                                                   [(ngModel)]="this.contentLG.texts[field][col.key][opt.key]"
                                                                      rows="2"
                                                            ></textarea>
                                                        </ng-container>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key]) == 'object'">
                                                <div class="divstring col-12" *ngFor="let opt of contentEN.texts[field][col.key] | keyvalue; let iopt = index">
                                                    <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][opt.key]) == 'array'">
                                                            <span *ngIf="component=='metadata'">=> Submenu ({{opt.key}})</span>
                                                            <div class="col-12" *ngFor="let arrayopt of contentEN.texts[field][col.key][opt.key] | keyvalue; let aopt = index">
                                                                <ng-container *ngIf="readonlyfields.indexOf(arrayopt.key) == -1">
                                                                <span>EN -> ({{contentEN.texts[field][col.key][opt.key][arrayopt.key].name}}) - {{contentEN.texts[field][col.key][opt.key][arrayopt.key].content}}</span><br>
                                                                <textarea
                                                                          type="text"
                                                                          class="form-control col-12"
                                                                          name="{{col.key+'_'+opt.key+aopt+arrayopt.key}}"
                                                                          [(ngModel)]="contentLG.texts[field][col.key][opt.key][arrayopt.key].content"
                                                                          rows="2"
                                                                ></textarea>
                                                                </ng-container>
                                                            </div>
                                                    </div>
                                                    <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][opt.key]) == 'string'">
                                                        <ng-container *ngIf="readonlyfields.indexOf(opt.key) == -1">
                                                        <span *ngIf="this.language!='en'">EN -> {{this.contentEN.texts[field][col.key][opt.key]}}</span><br>
                                                        <textarea [readOnly]="readonlyfields.indexOf(opt.key) != -1"
                                                               type="text"
                                                               class="form-control col-12"
                                                               name="{{col.key+'_'+opt.key+iopt}}"
                                                               [(ngModel)]="this.contentLG.texts[field][col.key][opt.key]"
                                                                  rows="2"
                                                        ></textarea>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- if the data is an array -->
                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field]) == 'array'">
                                    <!-- display text reference in English -->
                                    <div class="divstring col-12"
                                         *ngIf="checkForObject(contentEN.texts[field][col.key]) == 'string'">
                                        EN -> {{this.contentLG.texts[field][col.key]}}<br>
                                        <textarea type="text"
                                               class="form-control col-12"
                                               name="{{field+'_'+col.key+icol}}"
                                               [(ngModel)]="this.contentLG.texts[field][col.key]"
                                                  rows="2"
                                        ></textarea>
                                    </div>

                                    <div [hidden]="!submenuopen[field]" class="divstring col-12"
                                         *ngIf="(arraySkip.indexOf(component) != -1 || this.menuEN.submenu[menupage] || field == 'inject')">
                                        <div class="optionitems col-12"
                                             *ngIf="checkForObject(contentEN.texts[field][col.key]) == 'object' ||
                                             checkForObject(contentEN.texts[field][col.key]) == 'array'"
                                        >
                                            <!-- display type of the element -->
                                            <div *ngIf="contentEN.texts[field][col.key].layout" style="padding: 15px 0px 15px 0px; font-size: 16px">: : Element<span style="font-weight: bold"> - ({{contentEN.texts[field][col.key].layout}})</span></div>
                                            <!-- end type of element -->

                                            <div *ngFor="let el of contentEN.texts[field][col.key] | keyvalue">
                                                <div style="margin-bottom: 10px;" class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key]) == 'string' && readonlyfields.indexOf(el.key) == -1">
                                                    <ng-container *ngIf="readonlyfields.indexOf(el.key) == -1">
                                                        ({{el.key}}) {{this.contentLG.texts[field][col.key]}}<span class="marken">EN -> {{this.contentEN.texts[field][col.key][el.key]}}</span><br>
                                                    </ng-container>
                                                    <textarea *ngIf="readonlyfields.indexOf(el.key) == -1"
                                                              type="text"
                                                              class="form-control col-12"
                                                              name="{{field+col.key+'_'+el.key}}"
                                                              [(ngModel)]="this.contentLG.texts[field][col.key][el.key]"
                                                              rows="2"
                                                    ></textarea>
                                                </div>
                                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key]) == 'array'">
                                                    <div style="margin-bottom: 20px;" class="col-12" *ngFor="let opt of contentEN.texts[field][col.key][el.key] | keyvalue">
                                                        -> <span *ngIf="contentEN.texts[field][col.key].layout!='columns'">{{el.key}} ({{opt.key}})</span>
                                                        <span *ngIf="contentEN.texts[field][col.key].layout=='columns'">{{el.key}} ({{contentEN.texts[field][col.key][el.key][opt.key].topic}})</span>

                                                        <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key][opt.key]) == 'object'">
                                                            <div style="margin-bottom: 10px" class="col-12" *ngFor="let elkey of contentEN.texts[field][col.key][el.key][opt.key] | keyvalue; let i = index">
                                                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key][opt.key][elkey.key]) == 'object'">
                                                                    -> <span>{{elkey.key}}</span>
                                                                    <div style="margin-bottom: 10px; margin-left: 10px" class="col-12" *ngFor="let it of contentEN.texts[field][col.key][el.key][opt.key][elkey.key] | keyvalue">
                                                                        <ng-container *ngIf="readonlyfields.indexOf(it.key) == -1">
                                                                            ({{it.key}}) <span class="marken">EN -> {{this.contentEN.texts[field][col.key][el.key][opt.key][elkey.key][it.key]}}</span><br>
                                                                            <textarea
                                                                                      type="text"
                                                                                      class="form-control col-12"
                                                                                      name="{{field+'_'+contentEN.texts[field][col.key].layout+'_'+col.key+'_'+elkey.key+'_'+it.key}}"
                                                                                      [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key][elkey.key][it.key]"
                                                                                      rows="2"
                                                                            ></textarea>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key][opt.key][elkey.key]) == 'string'">
                                                                    <ng-container *ngIf="readonlyfields.indexOf(elkey.key) == -1">
                                                                        ({{elkey.key}}) <span class="marken">EN -> {{this.contentEN.texts[field][col.key][el.key][opt.key][elkey.key]}}</span><br>
                                                                        <textarea
                                                                                *ngIf="component == 'termsconditions' || component == 'privacypolicy'"
                                                                                type="text"
                                                                                class="form-control col-12"
                                                                                name="{{field+contentEN.texts[field][col.key].layout+col.key+opt.key}}"
                                                                                [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key][elkey.key]"
                                                                                rows="20"
                                                                        ></textarea>
                                                                        <textarea
                                                                                *ngIf="component != 'termsconditions' && component != 'privacypolicy'"
                                                                                  type="text"
                                                                                  class="form-control col-12"
                                                                                  name="{{field+contentEN.texts[field][col.key].layout+col.key+opt.key+i}}"
                                                                                  [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key][elkey.key]"
                                                                                  rows="2"
                                                                        ></textarea>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key][opt.key][elkey.key]) == 'array'">
                                                                    <div style="margin-bottom: 10px; margin-left: 10px" class="col-12" *ngFor="let it of contentEN.texts[field][col.key][el.key][opt.key][elkey.key] | keyvalue;let i = index">
                                                                        <div *ngIf="checkForObject(this.contentEN.texts[field][col.key][el.key][opt.key][elkey.key][it.key]) == 'object'">
                                                                            <div style="margin-bottom: 10px; margin-left: 10px" class="col-12" *ngFor="let list of contentEN.texts[field][col.key][el.key][opt.key][elkey.key][it.key] | keyvalue">
                                                                                <ng-container *ngIf="readonlyfields.indexOf(list.key) == -1">
                                                                                    ({{list.key}}) <span class="marken">EN -> {{this.contentEN.texts[field][col.key][el.key][opt.key][elkey.key][it.key][list.key]}}</span><br>
                                                                                    <textarea
                                                                                              type="text"
                                                                                              class="form-control col-12"
                                                                                              name="{{contentEN.texts[field][col.key].layout+'_'+opt.key+'_'+list.key+i}}"
                                                                                              [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key][elkey.key][it.key][list.key]"
                                                                                              rows="2"
                                                                                    ></textarea>
                                                                                </ng-container>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key][opt.key][elkey.key][it.key]) == 'string'">
                                                                            <ng-container *ngIf="readonlyfields.indexOf(it.key) == -1">
                                                                                ({{it.key}}) <span class="marken">EN -> {{this.contentEN.texts[field][col.key][el.key][opt.key][elkey.key][it.key]}}</span><br>
                                                                            <textarea
                                                                                      type="text"
                                                                                      class="form-control col-12"
                                                                                      name="{{field+'_'+contentEN.texts[field][col.key].layout+'_'+opt.key+'_'+elkey.key+'_'+it.key}}"
                                                                                      [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key][elkey.key][it.key]"
                                                                                      rows="2"
                                                                            ></textarea>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style="margin-bottom: 10px" class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key][opt.key]) == 'string'">
                                                            <ng-container *ngIf="readonlyfields.indexOf(opt.key) == -1">
                                                                ({{opt.key}}) <span class="marken">EN -> {{this.contentEN.texts[field][col.key][el.key][opt.key]}}</span><br>
                                                                <textarea
                                                                          type="text"
                                                                          class="form-control col-12"
                                                                          name="{{field+'_'+contentEN.texts[field][col.key].layout+'_'+'_'+opt.key}}"
                                                                          [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key]"
                                                                          rows="2"
                                                                ></textarea>
                                                            </ng-container>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="checkForObject(contentEN.texts[field][col.key][el.key]) == 'object'">
                                                    <div class="divstring col-12" *ngFor="let opt of contentEN.texts[field][col.key][el.key] | keyvalue">
                                                        <ng-container *ngIf="readonlyfields.indexOf(opt.key) == -1">
                                                            <span class="marken">EN -> {{this.contentEN.texts[field][col.key][col.key][el.key][opt.key]}}</span><br>
                                                        <textarea
                                                                  type="text"
                                                                  class="form-control col-12"
                                                                  name="{{el.key+'_'+opt.key}}"
                                                                  [(ngModel)]="this.contentLG.texts[field][col.key][el.key][opt.key]"
                                                                  rows="2"
                                                        ></textarea>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divstring col-10" *ngIf="checkForObject(contentEN.texts[field]) == 'string'">
                                    <span style="font-weight: bold">({{field}})</span> EN -> {{contentEN.texts[field]}}
                                    <textarea
                                            type="text"
                                            class="form-control col-12"
                                            name="{{field}}"
                                            [(ngModel)]="contentLG.texts[field]"
                                            rows="2">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divstring col-10" *ngIf="checkForObject(contentEN.texts[field]) == 'string'">
                        <ng-container *ngIf="readonlyfields.indexOf(field) == -1">
                        <span style="font-weight: bold">({{field}})</span> EN -> {{contentEN.texts[field]}}
                        <textarea
                                type="text"
                                class="form-control col-12" name="{{field}}"
                                [(ngModel)]="contentLG.texts[field]"
                                rows="2"
                        >
                        </textarea>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
        <button (click)="scrollUp()" class="btn btn-primary buttonup">
            <img src="assets/img/up-arrow.png" />
        </button>
    </div>
</div>
