<div id="footer" *ngIf="display.displayparent">
    <div class="footersmall" *ngIf="footer==0">
        <div class="form-group row" *ngIf="!mobile.open">
            <div class="col-3">
                <label style="font-weight: bold">{{footertext.headerheadoffice}}</label>
                <div class="col-12 address">
                    <span>{{footertext.number}}</span>
                    <span>{{footertext.streetaddress}}</span>
                    <span>{{footertext.suburbaddress}}</span>
                </div>
                <div class="col-12 address">
                    <span></span>
                    <span><strong>{{footertext.keys[0]}}:</strong> {{footertext.contact}}</span>
                    <span><strong>{{footertext.keys[1]}}:</strong> {{footertext.email}}</span>
                </div>
            </div>
            <div class="col-3">
                <label style="font-weight: bold">{{footertext.headersocial}}</label>
                <ul>
                    <a *ngFor="let s of footertext.social | keyvalue" href="{{footertext.social[s.key].link}}" target="_blank">
                        <li><img src="assets/img/{{footertext.social[s.key].img}}" />{{footertext.social[s.key].text}}</li>
                    </a>
                </ul>
            </div>
            <div class="col-6">
                <div style="width: 40%; float: left">
                    <label style="font-weight: bold">{{footertext.headerinfo}}</label>
                    <ul>
                        <!-- <a *ngFor="let info of footertext.information | keyvalue" [routerLink]="footertext.information[info.key].link">
                            <li>{{footertext.information[info.key].text}}</li>
                        </a> -->
                    </ul>
                </div>
                <div style="width: 60%; float: right">
                    <div class="footerlogo" [ngClass]="mobile.open?'hidefooterlogo':''">
                        <div class="row" style="position: absolute; bottom: 0px; right: 20px">
                            <div class="col-12" style="padding: 0px !important;">
                                <img src="assets/img/logo2.png" />
                            </div>
                            <div class="col-12" style="padding: 0px !important;">
                                <span style="font-size: 12px">&copy; {{footertext.copyright}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="form-group row" style="margin: 0px" *ngIf="mobile.open && mobile.size > 480 && mobile.size < 1060">
            <div class="row">
            <div class="col-4">
                <label style="font-weight: bold">{{footertext.headerheadoffice}}</label>
                <div class="col-12 address">
                    <span>{{footertext.number}}</span>
                    <span>{{footertext.streetaddress}}</span>
                    <span>{{footertext.suburbaddress}}</span>
                </div>
                <div class="col-12 address">
                    <span></span>
                    <span><strong>Phone:</strong> {{footertext.contact}}</span>
                    <span><strong>Email:</strong> {{footertext.email}}</span>
                </div>
            </div>
            <div class="col-4" style="margin-top: 20px">
                <label style="font-weight: bold">{{footertext.headersocial}}</label>
                <ul>
                    <a *ngFor="let s of footertext.social | keyvalue" href="{{footertext.social[s.key].link}}" target="_blank">
                        <li><img src="assets/img/{{footertext.social[s.key].img}}" />{{footertext.social[s.key].text}}</li>
                    </a>
                </ul>
            </div>
            <div class="col-4" style="margin-top: 20px">
                <label style="font-weight: bold">{{footertext.headerinfo}}</label>
                <ul>
                    <!-- <a *ngFor="let info of footertext.information | keyvalue" [routerLink]="footertext.information[info.key].link">
                        <li>{{footertext.information[info.key].text }}</li>
                    </a> -->
                </ul>
            </div>
            </div>
            <div style="float:left; width: 100%">
                <div class="col-12">
                    <div class="footerlogo" [ngClass]="mobile.open?'hidefooterlogo':''">
                        <div class="row" style="text-align: left">
                            <div class="col-12" style="padding: 0px !important; margin-top: 20px">
                                <img src="assets/img/logo2.png" />
                            </div>
                            <div class="col-12" style="padding: 0px !important;">
                                <span style="font-size: 12px">&copy; {{footertext.copyright}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row" style="margin: 0px" *ngIf="mobile.open && mobile.size < 480">
            <div class="row">
            <div class="col-12">
                <label style="font-weight: bold">{{footertext.headerheadoffice}}</label>
                <div class="col-12 address">
                    <span style="font-weight: bold"></span>
                    <span>{{footertext.number}}</span>
                    <span>{{footertext.streetaddress}}</span>
                    <span>{{footertext.suburbaddress}}</span>
                </div>
                <div class="col-12 address">
                    <span></span>
                    <span><strong>Phone:</strong> {{footertext.contact}}</span>
                    <span><strong>Email:</strong> {{footertext.email}}</span>
                </div>
            </div>
            <div class="col-12" style="margin-top: 20px">
                <label style="font-weight: bold">{{footertext.headersocial}}</label>
                <ul>
                    <a *ngFor="let s of footertext.social | keyvalue" href="{{footertext.social[s.key].link}}" target="_blank">
                        <li><img src="assets/img/{{footertext.social[s.key].img}}" />{{footertext.social[s.key].text}}</li>
                    </a>
                </ul>
            </div>
            <div class="col-12" style="margin-top: 20px">
                <label style="font-weight: bold">{{footertext.headerinfo}}</label>
                <ul>
                    <!-- <a *ngFor="let info of footertext.information | keyvalue" [routerLink]="footertext.information[info.key].link">
                        <li>{{footertext.information[info.key].text | titlecase }}</li>
                    </a> -->
                </ul>
            </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="footerlogo">
                        <div class="row" [ngClass]="mobile.open && mobile.size < 790 ?'footerlogolg':'footerlogosm'">
                            <div class="col-12">
                                <img src="assets/img/logo2.png" />
                            </div>
                            <div class="col-12" >
                                <span style="font-size: 12px">&copy; {{footertext.copyright}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footerwindow" *ngIf="footer==1">
        <div class="messagetop text-center">
            <p class="text-center">{{footertext.topmessage}}</p>
        </div>
        <div class="form-row">
            <div id="footerlg" class="footerleft col-4">
                <div class="form-group row">
                    <div class="col-2"></div>
                    <div class="col-10" class="address">
                        <p>{{footertext.copyright}}</p>
                        <p>{{footertext.streetaddress}}</p>
                        <p>{{footertext.suburbaddress}}</p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-2"></div>
                    <div class="col-10" class="contact">
                        <span>{{footertext.contact}}</span><br>
                        <span>{{footertext.email}}</span>
                    </div>
                </div>
            </div>
            <div id="footerright" class="col-8">
                <form>
                    <div class="form-group row">
                        <label for="contactname" class="col-sm-2 col-form-label">Name:</label>
                        <div class="col-sm-10">
                            <input name="name"  id="contactname" type="text" [(ngModel)]="emaildata.name" class="form-control form-control-lg" value="">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="contactemail" class="col-sm-2 col-form-label">Email:</label>
                        <div class="col-sm-10">
                            <input name="email" id="contactemail" type="text" [(ngModel)]="emaildata.email" class="form-control form-control-lg" value="">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="contactmessage" class="col-sm-2 col-form-label">Message:</label>
                        <div class="col-sm-10">
                            <textarea name="message" id="contactmessage" [(ngModel)]="emaildata.message"
                                    type="text"
                                    class="form-control form-control-lg"
                                    value=""></textarea>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">&nbsp;&nbsp;</label>
                        <mat-checkbox class="materialcheckbox col-10 row"
                                      [checked]="emaildata.subscription"
                                      (change)="dataservice.setChecked($event.checked)">
                            {{footertext.subscription}}
                        </mat-checkbox>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-2 col-form-label">&nbsp;</label>
                        <div class="col-sm-10">
                            <button type="button" (click)="sendEmail()" class="btn btn-two" name="submit" style="float: left">
                                SUBMIT
                                <div *ngIf="loading" class="spinner-border" role="status" style="float: right; width: 20px; height: 20px; margin-left: 10px">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <div class="alert" *ngIf="error.status" style="float: left">{{error.msg}}</div>
                        </div>
                    </div>
                </form>
            </div>
            <div id="footersm" class="col-12">
                <div class="form-group row">
                    <div class="col-2"></div>
                    <div class="col-10" class="address">
                        <p>{{footertext.copyright}}</p>
                        <p>{{footertext.streetaddress}}</p>
                        <p>{{footertext.suburbaddress}}</p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-2"></div>
                    <div class="col-10" class="contact">
                        <span>{{footertext.contact}}</span><br>
                        <span>{{footertext.email}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
