import {Component, Inject, Input, OnInit, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import { DataService } from "../../data.service";
declare var $: any;
import {Helpers} from "../../app.helpers";
import {ErrorMSG} from "../../app.models";
import {isPlatformBrowser} from "@angular/common";
declare var require: any;

@Pipe({
  name: 'removeWhiteSpace'
})
export class RemoveWhiteSpacePipe implements PipeTransform {

  transform(value: any): any {
    if (value === undefined)
      return 'undefined';
    return value.replace(/\s/g, "");
  }

}

@Component({
  selector: 'app-app-admin',
  templateUrl: './app-admin.component.html',
  styleUrls: ['./app-admin.component.scss']
})
export class AppAdminComponent implements OnInit {
  public language = 'en';
  public menuEN = {fields:[],texts:{},submenu:{}};
  public menuLG = {fields:[],texts:{},submenu:{}};
  public contentEN = {fields:[],texts:{}};
  public contentLG = {fields:[],texts:{}};
  public arraySkip = this.helpers.arraySkip();
  public section = 'HOME';
  public component: string = '';
  public menupage = '';
  public cmenu = 0;
  public readonlyfields: any = this.helpers.getReadOnly();
  public getlink = {};
  public error: ErrorMSG = this.helpers.error();
  public searcherror: ErrorMSG = this.helpers.error();
  public seachsections = [];
  public loading = false;
  private pagefilename = '';
  public pagetype = 0;
  public submenuopen = {};
  private keyfile = 'texts';

  constructor(
      public dataService: DataService,
      public helpers: Helpers,
      @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.dataService.setLinkAdmin(['index',null]);
    const menuschema = require('../../jsondata/en/menu.help.json');
    this.dataService.thislanguage.subscribe(lg => this.language = lg);
    let display = {displayparent: false, displaylinkbar: false, textbanner: true, displaybanner: false, margintopfix: false};
    this.dataService.updateDisplay(display);
    this.menuEN.fields = Object.keys(menuschema.texts);
    this.menuEN.texts = this.dataService.getTexts('menu', 'texts', null, 'en');
    this.menuEN.submenu = this.dataService.getTexts('menu', 'submenu', null, 'en');
    this.cmenu = Object.keys(this.menuEN.texts).length;

    this.menuLG.fields = Object.keys(menuschema['texts']);
    this.dataService.thismenu.subscribe(menu => this.menuLG.texts = menu);
    this.dataService.thissubmenu.subscribe(submenu => this.menuLG.submenu = submenu);

    this.setSectionChange('index');

    // if (isPlatformBrowser(this.platformId)) {
    //   $(function () {
    //     $(document).on('scroll', function () {
    //       var height = $(window).scrollTop().valueOf();
    //       if(height >= 150) {
    //         $(".savediv").css({'position':'fixed','bottom':'0px'});
    //       }
    //       else {
    //         $(".savediv").css({'position':'unset','bottom':'unset'});
    //       }
    //     })
    //   });
    // }
  }

  scrollUp() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }

  trackByFn(index, item) {
    return index;
  }

  /* get the text display in the website for submeny */
  getMenuText(field: string = '') {
    if(this.menupage != '' && field != 'pagetype') {
      const opt = this.menuEN.submenu[this.menupage].find(m => m.option == field);
      if(typeof opt != 'undefined') {
        return opt.topic;
      }
    }
  }

  openMenu() {
    $("mat-list").toggle( );
  }

  setSectionChange(component: string, page: string = null) {
    if(page != null) {
      component = page;
    }
    this.submenuopen = {};
    this.error = this.helpers.error();

    this.dataService.setLinkAdmin([component,page]);
    const getsection = this.helpers.search(this.helpers.sections(),component,'name');
    this.component = component;
    this.section = page != null ? 'MENU -> '+this.menuEN.texts[page] : getsection['text'];
    this.menupage = page == null ? '' : page;
    this.pagetype = 0;

    this.getPageFile(component,page);
    this.dataService.setAdminSection({component:component,page:page});
    const data = { filename: this.pagefilename, lg: this.language };
    this.dataService.requestAPI('files',null,data).then(data => {
      let componentschema = {texts:{}};
      if(data.code == 'OK') {
        componentschema = JSON.parse(data.data);
      }
      else {
        componentschema = require('../../jsondata/en/'+this.pagefilename);
      }

      // get the texts from json file AU
      this.keyfile = this.component == 'menu' ? 'submenu' : 'texts';
      let dataEN = {};
      const content = this.dataService.getTexts(component,this.keyfile,page,'en');
      for(let k in content) {
        dataEN[k] = content[k];
      }
      this.contentEN.texts = dataEN;
      // get the texts from the current language
      this.dataService.updateAdminTexts(componentschema[this.keyfile]);
      this.dataService.thiscomponentadmin.subscribe(texts => this.contentLG.texts = texts);

      if(this.contentEN.texts['pagetype'] == 0 || this.component == 'menu') {
        this.contentEN.fields = Object.keys(componentschema[this.keyfile]);
        this.contentLG.fields = Object.keys(componentschema[this.keyfile]);
      }
      else {
        this.pagetype = 1;
        this.contentEN.texts = this.contentEN.texts['pages'];
        this.contentEN.fields = Object.keys(componentschema.texts['pages']);
        this.contentLG.fields = Object.keys(componentschema.texts['pages']);
        for(let item in this.contentLG.texts) {
          if(item == 'pages') {
            for(let p in this.contentLG.texts[item]) {
              this.contentLG.texts[p] = this.contentLG.texts[item][p];
            }
          }
          else {
            this.contentLG.texts[item] = this.contentLG.texts[item];
          }
        }
      }

      for(let sub in this.contentEN.fields) {
        this.submenuopen[this.contentEN.fields[sub]] = false;
      }

    });
  }

  openSubmenuDiv(sub:string = '') {
    if(sub != '') {
      for(let op in this.submenuopen) {
        if(op == sub) {
          this.submenuopen[sub] = this.submenuopen[sub] == true ? this.submenuopen[sub] = false : this.submenuopen[sub] = true;
        }
        else {

        }
      }
    }
  }

  getPageFile(component,page = null) {
    if(page != null && page != '') {
      this.pagefilename = 'menucontent.' + page + '.help.json';
    }
    else {
      this.pagefilename = component + '.help.json';
    }
  }

  checkForObject(field) {
    if(typeof field == 'string' || typeof field == 'number') {
      return 'string';
    }
    else {
      const check = Array.isArray(field) ? 'array' : 'object';
      return check;
    }
  }

  saveChanges() {
    this.loading = true;
    this.error = this.helpers.error();
    let file = '';
    this.dataService.thislinkadmin.subscribe(link => this.getlink = link);
    if(this.getlink[1] != '' && this.getlink[1] != null) {
      file = '/menucontent.'+this.getlink[0];
    }
    else {
      file = '/'+this.getlink[0];
    }
    let data = {};
    if(this.keyfile == 'texts') {
      if (this.contentLG.texts['pagetype'] == 1) {
        let savetext = {};
        for (let op in this.contentLG.texts['pages']) {
          this.contentLG.texts['pages'][op] = this.contentLG.texts[op];
        }
      }
      data = {
        texts: this.contentLG.texts
      }
    }
    else {
      data = {
        submenu: this.contentLG.texts
      }
    }

    this.dataService.updateFile('','updatefiles',this.keyfile,data,file,this.language).subscribe(data => {
      this.contentLG;
      this.error.status = true;
      this.loading = false;
      if(data.code == 'OK') {
        if(this.menupage != '') {
          this.dataService.updateFile('', 'updatefiles', 'texts', this.menuLG, '/menu', this.language).subscribe(data => {
            if(data.code == 'OK') {

            }
            else {
              this.error.msg = 'Error to update menu texts. ';
            }
          }, error => {
            this.error.msg = 'Error to update the website. Please, contact the support team';
          })
        }
        this.error.msg += 'Website updated successfully';
      }
      else {
        this.error.msg = data.message;
      }
    }, error => {
      this.error.msg = 'Error to update the website. Please, contact the support team';
    });
  }

  searchText(event) {
    if(event.length >= 3) {
      let data = {};
      data['lg'] = this.language;
      data['key'] = event;
      data['menu'] = this.helpers.getPages()['default'];
      this.dataService.requestAPI('search',sessionStorage.key,data).then(data => {
        this.searcherror.status = true;
        this.seachsections = [];
        if(data.code == 'OK') {
          this.searcherror.msg = data.message;
          let i = 0;
          for(let s of data.sections) {
            let file = s.split('.');
            if(s.indexOf('menucontent') != -1) {
              this.seachsections[i] = {file: s,opt: 'menu -> ' + file[1],component: 'menu', page: file[1]};
            }
            else {
              this.seachsections[i] = {file: s,opt: file[0], component: file[0], page: null };
            }
            i++;
          }
          if(this.seachsections.length >= 1) {
            this.searcherror.status = false;
          }
        }
        else {
          this.searcherror.msg = 'Error to search the text. Please, try again';
        }
      },error => {
        this.searcherror.status = true;
        this.searcherror.msg = 'System error.';
      })
    }
    else {
      this.seachsections = [];
    }
  }
}
