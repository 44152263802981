import {AfterViewInit, Component, Inject, NgZone, OnInit, Optional, PLATFORM_ID, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from "@angular/material/core";
import {EmailData, ErrorMSG} from "../app.models";
import {Router} from "@angular/router";
import {DataService} from "../data.service";
import {Helpers} from "../app.helpers";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {APP_BASE_HREF, isPlatformBrowser} from "@angular/common";
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
declare var $: any;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-app-contact',
  templateUrl: './app-contact.component.html',
  styleUrls: ['./app-contact.component.scss']
})
export class AppContactComponent implements OnInit, AfterViewInit {
  public contenttexts: any;
  public linkmenu = ['contactus'];
  public loading = false;
  public error: ErrorMSG;
  public showlink = 1;
  public structure: any;
  public messages: any = this.helpers.pageMessages('contact');

  public reactiveForm: UntypedFormGroup = new UntypedFormGroup({
    recaptchaReactive: new UntypedFormControl(null, Validators.required)
  });

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  companyFormControl = new UntypedFormControl('', [
    Validators.min(5),
  ]);
  nameFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.min(3),
  ]);
  subjectFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.min(10),
  ]);
  phoneFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.min(8),
    Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
  ]);
  inputFormControl = new UntypedFormControl('',[
    Validators.min(5),
    Validators.required,
  ])

  emailform = new UntypedFormGroup({
        companyname: this.companyFormControl,
        name: this.nameFormControl,
        email: this.emailFormControl,
        phone: this.phoneFormControl,
        message: this.inputFormControl,
        subject: this.subjectFormControl
      }
  )

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      public router: Router,
      public dataService: DataService,
      public helpers: Helpers,
      private ngZone: NgZone
  ) { }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);

      $('.phone').on('keypress', function (event) {
          var regex = new RegExp("^[()-0-9]+$");
          var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
          if (!regex.test(key)) {
            event.preventDefault();
            return false;
          }
      });
    }
    this.error = this.helpers.error();
    this.dataService.thiskeystructure.subscribe(structure => this.structure = structure);
    this.dataService.setKeyPage(this.structure,this.messages,'contact');
    this.dataService.thiscomponentkeys.subscribe(componentkey => this.messages = componentkey['messages']);

    this.dataService.thiscontent.subscribe(content => this.contenttexts = content);
    this.dataService.setBarTopic({topic:this.contenttexts.title,heading:''});
    let display = {displayparent: true, displaylinkbar: false, textbanner: false, displaybanner: true, margintopfix: false};
    this.dataService.updateDisplay(display);
  }

  ngAfterViewInit() {
    //console.log('component banner contact');
  }

  public sendEmail() {
    this.error.status = false;
    const test = this.dataService.checkFields(this.contenttexts.fields, this.emailform.value);
    console.log(this.emailform.value['message']);
    if(test === 0 && this.emailform.value['message'] != '') {
      this.loading = true;
      let data = {};
      data['emaildata'] = this.emailform.value;
      data['emailtype'] = 'info';
      this.dataService.insertAPI('sendemail', null, data).subscribe((data) => {
        this.loading = false;
        this.error.status = true;
        if(data.code == 'OK') {
          this.error.msg = this.messages.messageok ? this.messages.messageok : data.message;
          this.emailform.reset();
        }
        else {
          this.error.msg = this.messages.messagefault1 ? this.messages.messagefault1 : data.message;
        }
      }, error => {
        this.loading = false;
        this.error.status = true;
        this.error.msg = this.messages.messagefault3 ? this.messages.messagefault3 : error.message;
      })
    } else {
      this.error.status = true;
      this.error.msg = this.messages.errorfiels;
    }
  }
}
