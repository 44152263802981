
import { environment } from '../environments/environment';

const apiurl = environment.protocol+environment.APIUrl+environment.APIendpoint+environment.APIv;

export const apipaths = {
  'crypt':        apiurl + '/functionalities/crypt',
  'auth':         apiurl + '/login/auth',
  'admin':        apiurl + '/access/admin',
  'updatefiles':  apiurl + '/admin/updatefiles',
  'files':        apiurl + '/files/getall',
  'search':       apiurl + '/admin/searchkey',
  'sendemail':    apiurl + '/website/contact',
}
