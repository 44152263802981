<div id="menu" *ngIf="display.displayparent">
    <div class="form-row">
        <div class="col-12">
            <ul class="ulmenu">
                <div class="mainmenu" id="{{m.opt}}" *ngFor="let m of mainmenu" (click)="getMouse(m.opt)" (mouseenter)="getMouse(m.opt)">
                    <li id="menuitem-{{m.opt}}" *ngIf="m.visualiza == 1" [ngClass]="activemenu==m.opt?'activemenu':'inactivemenu'">
                        {{menutexts[m.opt] | uppercase}}
                    </li>
                </div>
            </ul>
        </div>
        <div id="submenu" [@openClose]>
            <div class="form-group row" id="submenu1" >
                <div
                        class="submenu col-3"
                        id="{{sub.key}}"
                        *ngFor="let sub of submenu1 | keyvalue"
                        (mouseenter)="actionHover($event)"
                >
                    <a style="width: 100%" *ngIf="submenu1[sub.key].link && submenu1[sub.key].fragment == ''" (click)="closeMenu()" [routerLink]="submenu1[sub.key].link">
                        <div>
                            <img src="assets/img/{{submenu1[sub.key].image}}" />
                        </div>
                        <span class="topic">{{submenu1[sub.key].topic | uppercase }}</span><br>
                        <span class="subtext">{{submenu1[sub.key].subtext1 }}</span><br>
                        <span class="subtext">{{submenu1[sub.key].subtext2 }}</span>
                    </a>
                    <a style="width: 100%" *ngIf="submenu1[sub.key].link && submenu1[sub.key].fragment != ''" (click)="closeMenu()" [fragment]="submenu1[sub.key].fragment" [routerLink]="submenu1[sub.key].link">
                        <div>
                            <img src="assets/img/{{submenu1[sub.key].image}}" />
                        </div>
                        <span class="topic">{{submenu1[sub.key].topic | uppercase }}</span><br>
                        <span class="subtext">{{submenu1[sub.key].subtext1 }}</span><br>
                        <span class="subtext">{{submenu1[sub.key].subtext2 }}</span>
                    </a>
                </div>
                <div class="col-4 closeopt">
                    <img src="assets/img/close-icon.png" name="closemenu" (click)="closeMenu()"/>
                </div>
            </div>
            <div class="form-group row" id="submenu2">
                <div class="submenu col-3" id="{{sub.key}}" *ngFor="let sub of submenu2 | keyvalue">
                    <a style="width: 100%" *ngIf="submenu2[sub.key].link && submenu2[sub.key].fragment == ''" (click)="closeMenu()" [routerLink]="submenu2[sub.key].link">
                        <div *ngIf="submenu2[sub.key].image">
                            <img src="assets/img/{{submenu2[sub.key].image}}" />
                        </div>
                        <span class="topic">{{submenu2[sub.key].topic | uppercase }}</span><br>
                        <span class="subtext">{{submenu2[sub.key].subtext1 }}</span><br>
                        <span class="subtext">{{submenu2[sub.key].subtext2 }}</span>
                    </a>
                    <a style="width: 100%" *ngIf="submenu2[sub.key].link && submenu2[sub.key].fragment != ''" (click)="closeMenu()" [fragment]="submenu2[sub.key].fragment" [routerLink]="submenu2[sub.key].link">
                        <div *ngIf="submenu2[sub.key].image">
                            <img src="assets/img/{{submenu2[sub.key].image}}" />
                        </div>
                        <span class="topic">{{submenu2[sub.key].topic | uppercase }}</span><br>
                        <span class="subtext">{{submenu2[sub.key].subtext1 }}</span><br>
                        <span class="subtext">{{submenu2[sub.key].subtext2 }}</span>
                    </a>
                </div>
                <div class="col-4 closeopt">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- menu mobile -->
<div id="mobilemenu" class="container-fluid">
    <ul>
        <div class="mainmenu" id="{{m.opt}}" *ngFor="let m of mainmenu" (click)="getMouse(m.opt)" (mouseenter)="getMouse(m.opt)">
            <li class="mobilemenu" id="li-{{m.opt}}" *ngIf="m.visualiza == 1">
                <span class="optmenu">{{menutexts[m.opt]}}</span><img id="img-{{m.opt}}" src="assets/img/down-arrow.png" />
                <ul id="ul-{{m.opt}}">
                    <li class="optsubmenu" *ngFor="let sub of submenu[m.opt] | keyvalue" (click)="dataservice.willNavigate(submenu[m.opt][sub.key].link);closeMenu()">{{submenu[m.opt][sub.key].topic | uppercase}}</li>
                </ul>
            </li>
        </div>
        <li class="fixedoptions"><a routerLink="/contact" title="{{topbartexts['contact'].title}}" (click)="closeMenu()">{{topbartexts['contact'].text}}</a></li>
    </ul>
    <div class="row">
        <div class="col" style="text-align: center">
            <a type="button" class="btn btn-one" (click)="closeMenu()" routerLink="/signup">Sign Up</a>
        </div>
        <div class="col" style="text-align: center">
            <a href="https://portal.vectordsitec.com" target="_blank" (click)="closeMenu()">Login</a>
        </div>
    </div>
</div>
