import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  PLATFORM_ID,
  Inject,
  Input,
  Optional,
  HostListener
} from '@angular/core';
import { DataService } from "../data.service";
import { Helpers } from "../app.helpers";
import {SignUp, EmailData, Order} from "../app.models";
import {ActivatedRoute, NavigationEnd, RouterModule} from "@angular/router";
import { Router } from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import {APP_BASE_HREF, isPlatformBrowser, isPlatformServer, Location} from "@angular/common";
import {environment} from "../../environments/environment";
import {Meta, Title} from "@angular/platform-browser";
import {REQUEST} from "@nguniversal/express-engine/tokens";
import {isNumeric} from "rxjs/internal-compatibility";
declare var $: any;
declare var require: any;

@Component({
  selector: 'app-contentmenu',
  templateUrl: './app-contentmenu.component.html',
  styleUrls: ['./app-contentmenu.component.scss']
})
export class AppContentmenuComponent implements OnInit, AfterViewInit {
  public thiscomponent = this.constructor.name;
  public childcomponent = this.constructor.name;
  public contentinjected: any;
  public windowsize = 6500;
  public contenttexts: any;
  public contentmore: any;
  public pagetype = 0;
  public layout: any = 0;
  public linkmenu: any;
  public showlink = 1;
  public currency = "REAL";
  public lines = {};
  public columns = [];
  public counters = [];
  public displayparent = true;
  private submenu: any;
  public fragmentleft: any;
  public fragment: string;
  public listsubopt: any = [];
  public customlink = '';
  public openmobile: any = {open: false, size: 6500};
  public hideitems: any = [];
  public sesubmenu: any;
  public menuopt = 'cloudservers';
  public leftactive = '';
  public order: any = {BUDGET:this.helpers.setOrder(),PERFORMANCE:this.helpers.setOrder(),STORAGE:this.helpers.setOrder()};
  public dclist: any = {};
  public dccolumns: any = {};
  public itemsorder = this.helpers.productService()[this.menuopt];
  public webstatistics: any = {};
  private language: any;
  public openimage = '';
  coptions = {
    duration: 3,
  };
  @Input() injected: any = false;
  @Input() injectedopt: any;

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      @Optional() @Inject(REQUEST) protected request: any,
      meta: Meta,
      private titleservice: Title,
      public helpers: Helpers,
      public dataservice: DataService,
      private activerouter: ActivatedRoute,
      private router: Router,
      private location: Location,
      private route: ActivatedRoute
  ) {

    this.linkmenu = this.router.url.substring(1).split('/');
    if (isPlatformServer(this.platformId)) {
      let language = 'pt';
      const host = this.request.get('host');
      let lg = host.split('.');
      if(host && host.length > 1) {
        if (environment.type == 'dev' || environment.type == 'devsta') {
          let testlg = this.helpers.domainToLg(lg[lg.length - 1]);
          language = typeof testlg != 'undefined' ? testlg : 'pt';
        }
        if(environment.type == 'sta') {
          let testlg = this.helpers.domainToLg(lg[lg.length - 1]);
          language = testlg != 'undefined' ? testlg : 'pt';
        }
        if(environment.type == 'prod') {
          if (lg[0] == 'www' && lg[lg.length - 1] == 'br') {
            language = 'pt'
          }
          else {
            let testlg = this.helpers.domainToLg(lg[lg.length - 1]);
            language = typeof testlg != 'undefined' ? testlg : 'en';
          }
        }
      }
      console.log('language content ===>>>'+language);

      const metadata = require('../jsondata/'+language+'/metadata.help.json')['texts'];

      if(this.linkmenu[0] != '' && this.linkmenu[1] != '') {
        if (typeof metadata['menucomponent'][this.linkmenu[0]] != 'undefined' && typeof metadata['menucomponent'][this.linkmenu[0]][this.linkmenu[1]]) {
          for (let m of metadata['menucomponent'][this.linkmenu[0]][this.linkmenu[1]]) {
            if(m.content != '') {
              meta.updateTag(m, "name='" + m.name + "'");
            }
          }
        }
      }
    }


    if(this.linkmenu[0] == '') {
      this.showlink = 0;
    }

    router.events.subscribe(val => {
      if (location.path() != "") {
        const route = location.path();
      }
    });
  }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    if(this.linkmenu[0] == 'notfound') {
      this.layout = '';
      this.router.navigate(['']);
    }

    if(typeof this.linkmenu != 'undefined' && this.linkmenu[0] != '') {
      let display = {displayparent: true, displaylinkbar: false, displaylink: 1,textbanner: false, displaybanner: true, margintopfix: false};
      this.dataservice.updateDisplay(display);
    }

    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }

    this.dataservice.thiscurrency.subscribe(c => this.currency == c);
    this.dataservice.thislanguage.subscribe(lg => this.language = lg);
    this.dataservice.thiswebstatic.subscribe(web => this.webstatistics = web);
    this.dataservice.thissubmenu.subscribe( submenu => this.submenu = submenu);
    this.fragmentleft = this.dataservice.getTexts('menu','fragment');

    if(this.injected != 'null' && this.injected) {
      this.contenttexts = this.dataservice.getTexts(this.injected,'texts')[this.injectedopt];
      this.dataservice.setContentInjected(this.injected, this.injectedopt);
      this.dataservice.thisinjected.subscribe(content => this.contentinjected = content);
    }
    else {
      this.dataservice.thiscontent.subscribe(content => this.contenttexts = content['pages']);
      this.dataservice.thiscontent.subscribe(content => this.contentmore = content['more']);
      this.dataservice.thiscontent.subscribe(content => this.columns = content['columns']);
    }

    if(this.injected == '' && this.contenttexts) {
      this.pagetype = this.contenttexts.pagetype ? this.contenttexts.pagetype : 0;
    }

    this.dataservice.thisopenobile.subscribe(opemobile => this.openmobile = opemobile);
    if(this.openmobile.open) {
      this.hideitems = this.helpers.hideInfo(this.linkmenu[0]);
    }

    if(this.linkmenu[0] != '' &&  this.linkmenu[1] != '') {
      if(this.submenu[this.linkmenu[0]]) {
        this.dataservice.thisleftmenu.subscribe(leftmenu => this.listsubopt = leftmenu);

        this.sesubmenu = this.submenu[this.linkmenu[0]].find(item => item.option == this.linkmenu[1]);
        if (this.sesubmenu) {
          if (this.helpers.customizePages().includes(this.linkmenu[1])) {
            this.customlink = this.linkmenu[0] + '/' + this.linkmenu[1] + '/customize';
          }
        }
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      function isOnScreen(elem) {
        // if the element doesn't exist, abort
        if( elem.length == 0 ) {
          return;
        }
        var $window = $(window);
        var viewport_top = window.scrollY;
        var viewport_height = $window.height();
        var viewport_bottom = viewport_top + viewport_height;
        var elem = $(elem);
        if(elem.offset()) {
          var top = elem.offset().top;
          var height = elem.height();
          var bottom = top + height;

          return (top >= viewport_top && top < viewport_bottom) ||
              (bottom > viewport_top && bottom <= viewport_bottom) ||
              (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
        }
      }
    }
    this.dataservice.thislinesblocks.subscribe(lines => this.lines = lines);
  }

  ngAfterViewInit(): void {
    //console.log('component content started');
    try {
      if (isPlatformBrowser(this.platformId)) {
        let pagen = 0;
        const subpage = this.linkmenu[1] ? this.linkmenu[1].split('#') : '';
        for(let i in this.submenu[this.linkmenu[0]]) {
          if (this.submenu[this.linkmenu[0]][i].option == subpage[0]) {
            pagen = parseInt(i);
          }
        }

        if(pagen == 0) {
          window.scrollTo(0, 0);
        }
        else {
          document.querySelector('#' + this.fragment).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
      }
    } catch (e) { }

  }

  public openImage(open,image='') {
    if(open==1) {
      this.openimage = image;
      $('#popupimage').modal('toggle')
    }
    else {
      this.openimage = '';
    }
  }

  public getMouse(event) {
    this.menuopt = event.id;
    this.dataservice.updateOptMenu('');
  }

  async getProducts(group: string = '') {
    this.dataservice.getProductsPrice(this.dclist,this.order[group],this.linkmenu).then(data => {
      this.itemsorder = data;
    });
  }

  getUlrImages(image: string = '') {
    return 'url(assets/img/' + image;
  }

  setLines(op,c = null) {
    let lines = [];
    let line = 0;
    let columns = op.columns ? op.columns : 2;
    let oplines = Math.round(op.options.length / columns) > 1 ? Math.round(op.options.length / columns) : 1;
    for(let l = 0; l < oplines; l++) {
      lines.push([]);
    }

    // create the two lines for the content according to the number of options
    let opt = 0;
    while(opt < op.options.length) {
      for (let i = 0; i < columns; i++) {
        if(typeof op.options[opt] != 'undefined') {
          lines[line].push(op.options[opt]);
        }
        opt++;
      }
      line++;
    }

    if(c == null) {
      return lines;
    }
    else {
      this.lines[c] = lines;
    }
  }

  setColumns(op) {
    let columns = op.columns ? op.columns : 2;
    let max = Math.round(op.options.length / columns);
    for(let l = 0; l < columns; l++) {
      this.columns.push([]);
    }

    // create the two lines for the content according to the number of options
    let count = 1;
    let col = 0;
    for(let opt in op.options) {
      if(count > max) {
        count = 1;
        col++;
      }
      if(typeof op.options[opt] != 'undefined') {
        this.columns[col].push(op.options[opt]);
      }
      count++;
    }
  }

  realoadData() {
    this.dataservice.thiscontent.subscribe(content => this.contenttexts = content);
  }

  setDataRedict(opt: string = '') {
    let servicecode = {};
    for(let p in this.dclist[opt]) {
      servicecode = this.dclist[opt][p].find(el => el.code == this.order[opt].dcid);
      if(servicecode) {
        this.order[opt].productcode = servicecode['serviceCode'];
        this.order[opt].servicecode = servicecode['serviceCode'];
        if (this.order[opt].servicecode != 0) {
          break;
        }
      }
    }
  }

  redirectCustomizeOrder(customlink: string = '', opt: string = '') {
    if(customlink != '') {
      if(this.linkmenu[0] == 'cloudservers' && opt != '') {
        this.dataservice.willNavigate(customlink, null, {order: JSON.stringify(this.order[opt])});
      }
    }
  }

  getOrderPrice(opt: string = '') {
    let returnprice = 0.00;
    this.dclist[opt]['orderprice'] = 0.00;
    for(let item in this.helpers.productService()[this.menuopt]) {
      if (this.order[item] != 0) {
        const finditem = this.itemsorder[item].find(el => el.id == this.order[opt][item]);
        if(typeof finditem != "undefined") {
          const price = this.itemsorder[item].find(el => el.id == this.order[opt][item]).price;
          if(price != -1) {
            returnprice += +(parseFloat(price)).toFixed(2);
            //this.dclist[opt]['orderprice'] += +(parseFloat(price)).toFixed(2);
          }
        }
        else {
          break;
        }
      }
    }
    return returnprice;
  }

}
