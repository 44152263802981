import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError, BehaviorSubject, Subscription} from 'rxjs';
declare var $: any;
import {catchError, retry} from 'rxjs/operators';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { apipaths } from './apipath';
import { environment } from '../environments/environment';
import { ErrorMSG, UserData, NewUser } from './app.models';
import { Helpers } from './app.helpers';
import set = Reflect.set;

const headers = new HttpHeaders({'Content-Type': 'application/json'});

@Injectable({
  providedIn: 'root'
})
export class DataService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  public user: UserData;
  public error: ErrorMSG;
  public newuser: NewUser;
  private crypt = '';
  private language = 'en';
  private submenu: any;
  private menu: any;
  private content: any;
  private getlines: any;
  testemail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  // dataservice observable //

  // set Language
  private  componentlg = new BehaviorSubject<string>('');
  thislanguage = this.componentlg.asObservable();

  // change text languages
  private component_topbar = new BehaviorSubject({});
  thistopbar = this.component_topbar.asObservable();

  private component_menu = new BehaviorSubject({});
  thismenu = this.component_menu.asObservable();

  private component_footer = new BehaviorSubject({});
  thisfooter = this.component_footer.asObservable();

  private component_content = new BehaviorSubject({});
  thiscontent = this.component_content.asObservable();

  private component_submenu = new BehaviorSubject({});
  thissubmenu = this.component_submenu.asObservable();

  private component_link = new BehaviorSubject({});
  thislink = this.component_link.asObservable();

  private component_linkadmin = new BehaviorSubject({});
  thislinkadmin = this.component_linkadmin.asObservable();

  private component_middlewindows = new BehaviorSubject({});
  thismiddlewindows = this.component_middlewindows.asObservable();

  private component_display = new BehaviorSubject({});
  thiscomponentdisplay = this.component_display.asObservable();

  private component_admin = new BehaviorSubject({});
  thiscomponentadmin = this.component_admin.asObservable();

  private set_admin = new BehaviorSubject({});
  thissetadmin = this.set_admin.asObservable();

  private adminsection = new BehaviorSubject({});
  thisadminsection = this.adminsection.asObservable();

  private contentadminen = new BehaviorSubject({});
  thiscontentadminen = this.contentadminen.asObservable();

  private component_dcproducts = new BehaviorSubject({});
  thiscomponentdcproducts = this.component_dcproducts.asObservable();

  private component_products = new BehaviorSubject({});
  thiscomponentproducts = this.component_products.asObservable();

  private error_update = new BehaviorSubject({});
  thiserror = this.error_update.asObservable();

  private openobile = new BehaviorSubject({});
  thisopenobile = this.openobile.asObservable();

  private optmenu = new BehaviorSubject('');
  thisoptmenu = this.optmenu.asObservable();

  private currency = new BehaviorSubject('');
  thiscurrency = this.currency.asObservable();

  private footertype = new BehaviorSubject(0);
  thisfootertype = this.footertype.asObservable();

  private webstatistics = new BehaviorSubject({});
  thiswebstatic = this.webstatistics.asObservable();

  private bartopic = new BehaviorSubject({});
  thistopic = this.bartopic.asObservable();

  private countstats = new BehaviorSubject({});
  thiscountstats = this.countstats.asObservable();

  private selectedlg = new BehaviorSubject({});
  thisselectedlg = this.selectedlg.asObservable();

  private leftmenu = new BehaviorSubject({});
  thisleftmenu = this.leftmenu.asObservable();

  private keystructyre = new BehaviorSubject({});
  thiskeystructure = this.keystructyre.asObservable();

  private component_key = new BehaviorSubject({});
  thiscomponentkeys = this.component_key.asObservable();

  private contentinject = new BehaviorSubject({});
  thisinjected = this.contentinject.asObservable();

  private linesblocks = new BehaviorSubject({});
  thislinesblocks = this.linesblocks.asObservable();


  // data of current user
  private componentusardata = new BehaviorSubject({});
  thisuserdata = this.componentusardata.asObservable();

  // table and total item from component to share to another component
  private component = new BehaviorSubject({});
  thiscomponent = this.component.asObservable();

  // data to update item in popupregister
  private componentUpdate = new BehaviorSubject({});
  thisdatashare = this.componentUpdate.asObservable();

  // data do share txt from component to popupregister
  private openmodal = new BehaviorSubject({});
  thismodalshare = this.openmodal.asObservable();



  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private helpers: Helpers
  ) { }

  // function to set the language
  setLanguage(data: string) {
    this.componentlg.next(data);
  }

  seletedLangue(data: object) {
    this.selectedlg.next(data);
  }

  // function to set values for the link URL in admin management
  setLinkAdmin(data: object) {
    this.component_linkadmin.next(data);
  }

  // function to set the values for the link URL
  setLink(data: any) {
    this.thismenu.subscribe(menu => this.menu = menu);
    this.thissubmenu.subscribe(submenu => this.submenu = submenu);
    this.thiscontent.subscribe(content => this.content = content);
    let links = {};
    let linkarray = [];
    let c = 1;
    if(data[0] != '') {
      for (let l of data) {
        if (c == 1) {
          links['link' + c] = [l];
          links['link' + c + '-text'] = typeof this.menu[l] != 'undefined' ? this.menu[l] : l == 'contact' ? this.content.title : l;
        } else if (c == 2 && data[1]) {
          let cksubmenu = data[1].split('/');
          if(cksubmenu[0].indexOf('#') != -1) {
            cksubmenu = cksubmenu[0].split('#');
          }
          links['link' + c + '-text'] = this.submenu[data[0]].find(sub => sub.option == cksubmenu[0].replace(/ /g, ""))['topic'];
        } else {
          links['link' + c + '-text'] = l.charAt(0).toUpperCase() + l.slice(1);
          const queryparams = links['link' + c + '-text'].split('?');
          if (queryparams.length) {
            links['link' + c + '-text'] = queryparams[0];
          }
        }

        if (c > 1) {
          let ct = 0;
          links['link' + c] = [];
          for (let lt of data) {
            links['link' + c].push(lt);
            ct++;
            if (ct == c) {
              break;
            }
          }
        }
        c++;
      }

      let st = 1;
      for (let l in links) {
        if (l == 'link' + st) {
          links[l] = links[l].join('/');
          st++;
        }
      }
    }

    for(var i = 0; i < c;i++) {
      if(links['link'+(i+1)]) {
        linkarray[i] = {link: links['link' + (i + 1)], text: links['link' + (i + 1) + '-text']};
      }
    }

      //this.fixlinkbar = this.fixLinkPosition(this.childcomponent);
    this.component_link.next(linkarray);
  }

  setErroUpdate(data: object) {
    this.error_update.next(data);
  }

  setAdmin(data: object) {
    this.set_admin.next(data);
  }

  setCurrency(data: string) {
    this.currency.next(data);
  }

  setWebstatic(data: object) {
    this.webstatistics.next(data);
  }

  setBarTopic(data:object) {
    this.bartopic.next(data);
  }

  setCounter(data:object) {
    this.countstats.next(data);
  }

  setLeftMenu(submenu:object,link:any) {
    let listsubopt = [];
    if(typeof link[0] != 'undefined' &&  typeof link[1] != 'undefined') {
      let listsubopt = [];
      const link1 = link[1].split('#');
      const sublink = link1.length > 1 ? link1[1] : link1[0];
      if(submenu[link[0]]) {
        for(let i of submenu[link[0]]) {
          if(i.option != 'empty') {
            i.active = i.link == link[0] + '/'+ sublink ? true : false
            listsubopt.push(i);
          }
        }
        this.leftmenu.next(listsubopt);
      }
    }
  }

  setKeyStructure(data: object) {
    this.keystructyre.next(data);
  }

  setKeyPage(data:object,message:object,page:string) {
    let setmessages = {};
    for(let k in message) {
      const val = data[page]['message'].filter(it => k == it['ref']);
      setmessages[k] = val[0]['text'];
    }
    this.component_key.next({messages:setmessages});
  }

  setContentInjected(injected:string,injectedopt:string) {
    const contenttexts = this.getTexts(injected,'texts')[injectedopt];
    this.contentinject.next(contenttexts);
  }

  setLinesBlocks(data: object) {
    this.linesblocks.next(data);
  }


  /** Functions component to update content **/
  updateTopBar(data: object) {
    this.component_topbar.next(data);
  }
  updateMenu(data: object) {
    this.component_menu.next(data);
  }
  updateSubMenu(data: object) {
    this.component_submenu.next(data);
  }
  updateFooter(data: object) {
    this.component_footer.next(data);
  }
  updateContent(data: object) {
    this.component_content.next(data);
  }
  updateDisplay(data: object) {
    this.component_display.next(data);
  }
  updateMiddleWindows(data: object, link: string) {
    let textwindow = {right:[],left:[]};
    const i = Object.keys(data[link]).length / 2;
    let count = 0;

    for(let col in data[link]) {
      if(count < i) {
        textwindow.left.push(data[link][col]);
      }
      else {
        textwindow.right.push(data[link][col]);
      }
      count++;
    }
    this.component_middlewindows.next(textwindow);
  }
  updateAdminTexts(data: object) {
    this.component_admin.next(data);
  }

  updateOptMenu(data: string) {
    this.optmenu.next(data);
  }

  /** End update component content **/

  // get link route
  updateLinkRoute(data: object) {
    this.component_link.next(data);
  }

  setAdminContentEN(data: object) {
    this.contentadminen.next(data);
  }

  setAdminSection(data:object) {
    this.adminsection.next(data);
  }

  setMobile(data) {
    this.openobile.next(data);
  }

  setDCProducts(data) {
    this.component_dcproducts.next(data);
  }

  setProducts(data) {
    this.component_products.next(data);
  }

  setFooter(data) {
    this.footertype.next(data);
  }


  // function to set user data from login
  setUserData(data: object) {
    this.componentusardata.next(data);
  }

  // function define table and total itens in the component
  setDataCopmponent(data: object) {
    this.component.next(data);
  }

  // function to set data to update tables
  setDataUpdate(data: object) {
    this.componentUpdate.next(data);
  }

  // function to share txt to the popupregister.component
  setOpenModal(data: object) {
    this.openmodal.next(data);
  }

  /*
  * function to get the object containing the texts to display on screen
  * component = component to bind the text
  * key = the key variable to get the values
  * page = use in case of specific page
  * return = object
   */
  public getTexts(component = null,key = null, page = '', lg = null, pagetype = null) {
    //this.setLinkAdmin([component,page]);
    let ret = {};
    if(lg != null) {
      this.language = lg;
    }
    else {
      this.thislanguage.subscribe(lg => {
        this.language = lg;
      });
    }
    ret = this.helpers.getJSON(component, key, this.language, page, pagetype);
    return ret;
  }

  setContentData(data: object, link = null) {
    let link1 = [];
    let newdata = {};
    for(let k in data) {
      newdata[k] = data[k];
    }

    let contenttext = [];
    let columns = [];
    if(typeof newdata != 'undefined' && newdata['pagetype'] == 1 && newdata['pages']) {
      let structure = [];
      let c = 0;
      if(typeof newdata['pages'][0] == 'undefined') {
        for(let pg in newdata['pages']) {
          for(let info in newdata['pages'][pg]) {
            contenttext.push(newdata['pages'][pg][info]);
            // if(this.contenttexts.pages[pg][info].layout == 'blocks') {
            //   this.setLines(this.contenttexts.pages[pg][info],c);
            // }
            if(newdata['pages'][pg][info].layout == 'listtickcolumns') {
              columns[info] = this.setColumns(newdata['pages'][pg][info]);
            }
            c++;
          }
        }
      }
    }
    if(newdata['pages'] && newdata['pagetype'] == 1) {
      if(typeof newdata['pages'][0] == 'undefined') {
        newdata['pages'] = contenttext;
      }
    }
    if(link != null && link.length > 1 && newdata['pagetype'] == 0) {
      if(typeof newdata['pages'] == 'undefined') {
        link1 = link[1].split("#");
        newdata['pages'] = newdata[link1[0]];
      }
    }
    newdata['columns'] = columns;
    return newdata;
  }

  setColumns(op) {
    let retcolumns = [];
    let columns = op.columns ? op.columns : 2;
    let max = Math.round(op.options.length / columns);
    for(let l = 0; l < columns; l++) {
      retcolumns.push([]);
    }

    // create the two lines for the content according to the number of options
    let count = 1;
    let col = 0;
    for(let opt in op.options) {
      if(count > max) {
        count = 1;
        col++;
      }
      if(typeof op.options[opt] != 'undefined') {
        retcolumns[col].push(op.options[opt]);
      }
      count++;
    }

    return retcolumns;
  }

  setLines(op) {
    let lines = [];
    let line = 0;
    let columns = op.columns ? op.columns : 2;
    let oplines = Math.round(op.options.length / columns) > 1 ? Math.round(op.options.length / columns) : 1;
    for(let l = 0; l < oplines; l++) {
      lines.push([]);
    }

    // create the two lines for the content according to the number of options
    let opt = 0;
    while(opt < op.options.length) {
      for (let i = 0; i < columns; i++) {
        if(typeof op.options[opt] != 'undefined') {
          lines[line].push(op.options[opt]);
        }
        opt++;
      }
      line++;
    }

    return lines;
  }

  // fornt-end simple encrypt
  encrypt(data) {
    let newdata = {};
    for(let k in data) {
      newdata[k] = btoa(btoa(data[k]));
    }
    return newdata;
  }

  // to decrypt and encrypt string
  public cryptDecrypt(string,action) {
    var data = {};
    data['datatocrypt'] = string;
    data['action'] = action;

    if(string !== '') {
      this.requestAPI('crypt', localStorage.keylogin, data).then((data) => {
        if (parseInt(data[0].status) === 401) {
          this.error.status = true;
          this.error.msg = data[0].message;
        } else {
          this.crypt = data[0]['crypt'];
        }
      });
      return this.crypt;
    }
    else{
      return 'undefined';
    }
  }

  // function to connect through the API to exec login
  public requestLogin(path,datalogin, token=null, remember=0): Observable<any> {
    const url = environment.production ? apipaths[path] : apipaths[path] + '?v=' + environment.type;
    let options = null;
    let data = {
      IP: sessionStorage.IP,
      access_token: sessionStorage.keylogin,
      user: undefined,
      password: undefined
    };
    if (token != null) {
      data.access_token = token;
    } else {
      data.user = datalogin.user;
      data.password = datalogin.password;
    }
    const params = new HttpParams({fromObject: data});
    options = { params, headers };
    return this.httpClient.post<any>(url, data, { headers: headers, withCredentials: true});
  }

  // function to connect through the API and SELECT data
  public async requestAPI(table, key= null, data= null): Promise<any> {
    const url = environment.production ? apipaths[table] : apipaths[table] + '?v=' + environment.type;
    let options = {};

    const params = new HttpParams().set("requestData", JSON.stringify(data));
    headers.set('jwt',document.cookie);

    options = { params, headers };

    return this.httpClient.get<any>(url, options ).toPromise();
  }

  // function to connect through the API and INSERT data
  public insertAPI(table, key= null, data= null): Observable<any> {
    const url = environment.production ? apipaths[table] : apipaths[table] + '?v=' + environment.type;

    const params = new HttpParams({fromObject: data});
    headers.set('jwt',document.cookie);

    return this.httpClient.post<any>(url, data, { headers: headers, withCredentials: true });
  }

  // function to connect through the API and UPDATE data
  public updateAPI(table, key= null, data= null, id= null): Observable<any> {
    const url = environment.production ? apipaths[table] + '/' + id : apipaths[table] + '/' + id + '?v=' + environment.type;
    const datainsert = { data: data };
    delete datainsert.data.id;
    headers.set('jwt',document.cookie);

    return this.httpClient.put<any>(url, datainsert, { headers:headers, withCredentials:true });
  }

  // function to connect through the API and DELETE data
  public deleteAPI(table, key= null, id= null): Observable<any> {
    let options = {};
    const url = environment.production ? apipaths[table] + '/' + id : apipaths[table] + '/' + id + '?v=' + environment.type;
    const datainsert = { data: [] };
    const params = new HttpParams({fromObject: datainsert});
    headers.set('jwt',document.cookie);
    options = { params, headers };

    return this.httpClient.delete<any>(url, options);
  }

  public updateFile(key=null,path=null,keychange='',data=null,file='',lg='en'): Observable<any> {
    const url = environment.production ? apipaths[path] : apipaths[path] + '?v=' + environment.type;
    const datainsert = { keylogin: key, data: data, keychange: keychange, file: file, lg: lg };
    const params = new HttpParams({fromObject: datainsert});
    return this.httpClient.post<any>(url, {datainsert, headers});
  }

  // function to navigate between the pages
  public willNavigate(action, from = null, params: any = []) {
    $('body').css('overflow-x','visible');
    $('body').css('overflow-y','visible');
    $("#submenu").hide();
    let links = action.split('/');
    if(links.length > 2) {
      links[1] = links[1] + '/' +links[2];
    }
    const content = this.helpers.getJSON(links[0],'texts',localStorage.lg,links[1]);
    this.updateContent(content);
    if(content['error']) {
      this.router.navigate(['./notfound']);
    }
    else {
      if(action == 'admin') {
        this.updateDisplay({displayparent:false});
      }
      else {
        this.updateDisplay({displayparent:true});
      }

      if(from == null) {
        if(params != null) {
          // set params to navigate the router
          this.router.navigate(['./' + action ], { queryParams: params });
        }
        else {
          this.router.navigate(['./' + action]);
        }
      }
    }
  }

  public changeSwitch(field, checkobj) {
    if( typeof (checkobj[field]) === 'number') {
      if (checkobj[field] === 0) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  public setChecked(event: boolean) {
    return event;
  }

  // Error handling
  errorHandl(error: HttpErrorResponse) {
     let errorMessage = '';
     if (error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     return errorMessage;
  }

  public logoutSession() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['./']);
  }

  public checkFields(fields, values) {
    let error = 0;
    for ( const fi of fields) {
      let key = fi;
      let required = 0;
      let minsize = 0;
      let minvalue = 0;
      if(typeof fi === 'object') {
        key = fi.name;
        required = fi.required;
        minsize = fi.minsize;
        minvalue = fi.minvalue;
      }
      if ( ((typeof values[key] === 'undefined' ||
          values[key] === '' || (values[key] == false && key != 'termsconditions')) &&
          required == 1) ||
          (minsize > 0 && values[key].length > 0 && values[key].length < minsize) ||
          (minvalue > 0 && values[key] < minvalue) ||
          (key == 'email' && !this.testemail.test(values[key]))) {
        error++;
      }
    }
    return error;
  }

  public backPageComponent(url = '',key='') {
    let links = url.split('/');
    if(links.length > 2) {
      links[1] = links[1] + '/' +links[2];
    }
    const content = this.getTexts(links[0],'texts',links[1]);
    this.updateContent(content);
    return 1;
  }

  // function to get all DC Products from portal API
  async getGroupProducts(service) {

    return await this.requestAPI('productgroup', null, null).then(response => {
      if (response.code == 'OK') {
        const list = response.productGroups;
        const portaltosite = this.helpers.portalOptToSite();

        let product = {dclistct:{},dclistgp:{}};
        for (let dc in list) {
          for (let opt in list[dc].datacenters) {
            if(list[dc].datacenters[opt].classes[portaltosite[service[1]]]) {
              let servicetype = null;
                servicetype = typeof list[dc].datacenters[opt].classes[portaltosite[service[1]]].groups[service[2]] != 'undefined' ? list[dc].datacenters[opt].classes[portaltosite[service[1]]].groups[service[2]].serviceCode : null;


                let itdc = {};
                if (servicetype != null) {
                  itdc = {
                    dcname: list[dc].datacenters[opt].name,
                    code: list[dc].datacenters[opt].code,
                    id: list[dc].datacenters[opt].id,
                    dccountrycode: list[dc].countryCode,
                    dccountryname: list[dc].countryName,
                    serviceCode: servicetype
                  };
                }

                if (itdc['dcname']) {
                  if (typeof product.dclistct[dc] != 'undefined') {
                    product.dclistct[dc].push(itdc);
                  } else {
                    product.dclistct[dc] = [];
                    product.dclistct[dc].push(itdc);
                  }
                }

                for(let gp in list[dc].datacenters[opt].classes[portaltosite[service[1]]].groups) {
                  let itdc = {};
                  itdc = {
                    dcname: list[dc].datacenters[opt].name,
                    code: list[dc].datacenters[opt].code,
                    id: list[dc].datacenters[opt].id,
                    dccountrycode: list[dc].countryCode,
                    dccountryname: list[dc].countryName,
                    serviceCode: list[dc].datacenters[opt].classes[portaltosite[service[1]]].groups[gp].serviceCode
                  };
                  if(product.dclistgp[gp]) {
                    if(product.dclistgp[gp][list[dc].countryCode]) {
                      product.dclistgp[gp][list[dc].countryCode].push(itdc);
                    }
                    else {
                      product.dclistgp[gp][list[dc].countryCode] = [];
                      product.dclistgp[gp][list[dc].countryCode].push(itdc);
                    }
                  }
                  else {
                    product.dclistgp[gp] = {};
                    product.dclistgp[gp][list[dc].countryCode] = [];
                    product.dclistgp[gp][list[dc].countryCode].push(itdc);
                  }
                }
            }
          }
        }
        this.setDCProducts(product);

        return {code:'OK','list': product};
      }
      else {
        return {error:'Error to retrieve the data. Please, try again in couple of minutes.',code:'FAIL'};
      }
    },error => {
      return {error:'Error to connect the soruce. Please, try again later',code:'FAIL'};
    });
  }

  async getProductsPrice(dclist,order,linkmenu,servicecode = null) {
    for(let c in dclist) {
      servicecode = servicecode == null ? dclist[c].find(el => el.code == order.dcid): servicecode;
      if (servicecode) {
        break;
      }
    }
    if(servicecode) {
      order.productcode = servicecode.serviceCode;
      return this.getProducts(servicecode.serviceCode, linkmenu[0]).then(data => {
        return data;
      });
    }
  }

  changeCurrency(c) {
    this.setCurrency(c);
    return c;
  }

  // function to get all products from portal API
  async getProducts(service, type: string = ''): Promise<void> {
    const portaltosite = this.helpers.portalOptToSite();
    var data = {};
    var currency = 'AUD';
    this.thiscurrency.subscribe(cur => currency  = cur);
    data['serviceCode'] = service;
    data['currency'] = currency;
    let response = this.requestAPI('product', null, data).then(response => {
      if (response.code == 'OK') {
        let itemsorder = this.helpers.productService();
        itemsorder[type]['cpu'] = Object.values(response.product['options']['CPU']);
        itemsorder[type]['memory'] = Object.values(response.product['options']['MEMORYGB']);
        itemsorder[type]['storage'] = Object.values(response.product['options']['STORAGEGB']);
        itemsorder[type]['network'] = Object.values(response.product['options']['TRAFFICGB']);
        itemsorder[type]['os'] = Object.values(response.product['options']['OPERATINGSYSTEM']);
        this.setProducts(itemsorder);
        return itemsorder[type];
      }
      else {
        return [];
      }
    });
    return response;
  }

  resetError() {
    this.error.status = false;
    this.error.msg = '';
  }
}
