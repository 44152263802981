<div id="contentlogin">
    <div class="text-center" style="margin-top: 40px">
        <h3 class="pink-text mb-5"><strong>ADMIN</strong></h3>
    </div>
    <div class="divlogin text-center">
        <div class="form-group">
            <mat-form-field>
                <mat-label>email</mat-label>

                <input type="text" matInput [formControl]="emailFormControl"
                       (change)="updateValue($event.target.value,'user')"
                />
                <mat-hint>email is <strong>required</strong></mat-hint>
                <mat-error>
                    Please enter a valid email address
                </mat-error>
                <mat-error>
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>

                <input type="password" matInput [formControl]="passwordFormControl"
                       (change)="updateValue($event.target.value,'password')"
                />
                <mat-hint>pasword is <strong>required</strong></mat-hint>
                <mat-error>
                    Email is <strong>required</strong>
                </mat-error>

            </mat-form-field>
            <div class="col-12">
                <button mat-raised-button color="primary" (click)="logIn()">Log in</button>
            </div>
            <div class="alert alert-warning" style="margin-top: 15px" *ngIf="error.status">{{error.msg}}</div>
        </div>
    </div>
</div>
