import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';

import {AppIndexComponent} from "./app-index/app-index.component";
import { AppContentmenuComponent} from "./app-contentmenu/app-contentmenu.component";
import {AppSignupModule} from "./app-signup/app-signup.module";
import {AppSignupComponent} from "./app-signup/app-signup.component";
import {AppAdminComponent} from "./admin/app-admin/app-admin.component";
import {AccessGuard} from "./accessguard";
import {AppAdminloginComponent} from "./admin/app-adminlogin/app-adminlogin.component";
import {AppContactComponent} from "./app-contact/app-contact.component";
import {AppContentcustomizeComponent} from "./app-contentcustomize/app-contentcustomize.component";


const routes: Routes = [
  { path: '', component: AppIndexComponent,
    children: []
  },
  {
    path: 'notfound',
    component: AppIndexComponent
  },
  {
    path: '404',
    redirectTo: 'notfound'
  },
  {
    path: 'signup',
    loadChildren: () => import('./app-signup/app-signup.module').then(s => s.AppSignupModule)
  },
  {
    path: 'contact',
    component: AppContactComponent
  },
  {
    path: 'login-admin',
    component: AppAdminloginComponent,
    canActivate: [AccessGuard]
  },
  {
    path: 'admin',
    component: AppAdminComponent,
    canActivate: [AccessGuard]
  },
  {
    path: 'apps',
    children: [{
      path: 'sigma',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'findme',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'thebrain',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    }]
  },
  {
    path: 'technology',
    children: [{
      path: 'uiux',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'ai',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'bintelligence',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    }]
  },
  {
    path: 'aboutus',
    children: [
    {
      path: 'ourmission',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'whatwedo',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'howwedo',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    }
    ]
  },
  {
    path: 'customers',
    children: [
      {
        path: 'customersget',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      },
      {
        path: 'customersupport',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      },
      {
        path: 'customerpartner',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      }
    ]
  },
  {
    path: 'consulting',
    children: [
      {
        path: 'websites',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      },
      {
        path: 'saasprojects',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      },
      {
        path: 'business',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      },
      {
        path: 'businessai',
        loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
      }
    ]
  },
  {
    path: 'partners',
    children: [{
      path: 'infrastructurepartners',
      component: AppContentmenuComponent,
    },
    {
      path: 'resellerpartners',
      component: AppContentmenuComponent,
    },
    {
      path: 'referralpartners',
      component: AppContentmenuComponent,
    }]
  },
  {
    path: 'whyus',
    children: [{
      path: 'whyustools',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'whyuscompany',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    },
    {
      path: 'whytrusus',
      loadChildren: () => import('./app-contentmenu/app-contentmenu.module').then(m => m.AppContentmenuModule)
    }]
  },
  {
    path: '**',
    redirectTo: '/notfound'
  },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 100],
  enableTracing: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
