<app-linkbar [linkmenu]="linkmenu" [showlink]="showlink" [childcomponent]="childcomponent"></app-linkbar>
<div id="popupimage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" style="width:80% !important; max-width:80% !important;">
    <div class="modal-content">
        <div class="modal-body">
            <img src="assets/img/{{openimage}}" class="img-responsive" style="width: 100% !important; max-height: 100vh !important">
        </div>
    </div>
  </div>
</div>
<div id="contentmenu"
     class="container-fluid row wrap"
     style="padding: 0px"
     (mouseenter)="getMouse($event)"
    [ngClass]="openmobile.open ? 'margintopcontent':''">

    <div id="contentleft" class="col-3 sidebar-content" *ngIf="openmobile.size > 960 && injected == ''">
        <div id="floatmenu" class="floatmenu" [ngClass]="openmobile.size > 1330 ? 'col-2' : 'col-3'" >
            <ul *ngIf="fragmentleft[linkmenu[0]]">
                <a *ngFor="let opt of listsubopt"><li [fragment]="opt.option" [routerLink]="'.'" [ngClass]="opt.active ? 'leftactive' : ''"><span style="margin-right:5px; font-size:14px">&#10148;</span> {{opt.topic}}</li></a>
            </ul>
            <ul *ngIf="!fragmentleft[linkmenu[0]]">
                <a *ngFor="let opt of listsubopt" [routerLink]="'/'+opt.link"><li [ngClass]="opt.active ? 'leftactive' : ''"><span style="margin-right:5px;font-size:14px">&#10148;</span> {{opt.topic}}</li></a>
            </ul>
        </div>
        <div class="row more">
            <div class="col-12">
                <div class="moretopic"><h5>{{contentmore.topic}}</h5></div>
                <ul>
                    <li *ngFor="let opt of contentmore.opt" [routerLink]="opt.link">
                        {{opt.text}}
                        <span>{{opt.description}}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div id="contentright"
         class="main-content"
         [ngClass]="[openmobile.size > 960 ? 'col-9':'col-12',injected != '' ? 'removetop col-12' : '']"
         style="padding-right: 0px !important;">
        <div style="margin-bottom: 30px" *ngFor="let content of contenttexts;let key = index">
            <div *ngIf="contenttexts[key].id" [attr.id]="contenttexts[key].id" style="position: relative; top: -150px; left: 0"></div>

            <div *ngIf="contenttexts[key].topic && contenttexts[key].typeheader == 0" class="headerpage">
                <h1 class="headingcontent" >{{contenttexts[key].topic}}</h1>
                <h4 *ngIf="contenttexts[key].heading" [innerHTML]="contenttexts[key].heading"></h4>
            </div>

            <!-- error message content -->
            <div [attr.id]='' class="notfound form-group row" *ngIf="contenttexts[key].layout==0" [ngClass]="key+1 < contenttexts.length ? 'divspace': ''">
                <div class="col">
                    <div id="error" class="col-12"><img src="assets/img/error_404.jpg" name="notfound" id="notfounfimg" /> </div>
                    <div id="message" class="col-12">{{contenttexts[key].message}}</div>
                </div>
            </div>

            <!-- header -->
            <div class="col-12" id="header{{key}}" *ngIf="contenttexts[key].layout=='header' && contenttexts[key].typeheader == 1" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row headercontet" id="{{key}}">
                        <h1 *ngIf="contenttexts[key].topic != '' && contenttexts[key].typeheader == 0">{{ contenttexts[key].topic}}</h1>
                        <h3 *ngIf="contenttexts[key].topic != '' && contenttexts[key].typeheader == 1">{{ contenttexts[key].topic}}</h3>
                        <p *ngIf="contenttexts[key].heading">{{contenttexts[key].heading}}</p>
                </div>
            </div>

            <!-- image full page -->
            <div *ngIf="contenttexts[key].layout=='fullimage'" id="fullimage{{key}}" class="fullimage" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="row">
                    <img src="assets/img/{{contenttexts[key].img}}" />
                    <span style="display: inline-block;width: 100%; text-align: center">{{contenttexts[key].text}}</span>
                </div>
            </div>

            <!-- columns to display comparisons or different serivces and prices -->
            <div *ngIf="contenttexts[key].layout=='columns'" id="columns{{key}}" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row">
                    <div *ngFor="let text of contenttexts[key].options" class="col layout1col" >
                        <div class="contenttopic col-12" [innerHTML]="text.topic"><img src="assets/img/{{text.img}}" *ngIf="text.img"></div>
                        <div class="contentprice col-12">
                            <span class="contentvalue" *ngIf="text.price != '' && linkmenu[0] == 'cloudservers' && order[text.dcgroup].price">{{currency}} ${{order[text.dcgroup].price}}</span>
                            <span class="contentvalue" *ngIf="text.price != '' && text.price != 'contact us' && linkmenu[0] != 'cloudservers'">{{currency}} {{text.simbol}}{{text.price}}</span>
                            <span class="contentvalue" *ngIf="text.price == 'contact us' && linkmenu[0] != 'cloudservers'"><a routerLink="/contact" class="contentvalue">{{text.price}}</a></span>
                            <span style="font-weight: bold; display: inline-block; width: 100%; margin-bottom: 10px"> {{text.frequency}}</span>
                            <span style="display: inline-block;width: 100%" *ngIf="text.details != ''" [innerHTML]="text.details"></span>
                        </div>
                        <div class="contentdetails col-12">
                            <span *ngIf="text.where" style="margin-bottom: 20px; display: inline-block">{{text.where}}</span>
                            <span *ngIf="text.heading" style="margin-bottom: 20px; display: inline-block">{{text.heading}}</span>

                            <div *ngFor="let det of text.info | keyvalue" style="width: 100%" class="row col-12" style="padding:0px; margin:0px">
                                <div class="col-6" *ngIf="helpers.searchArr(det.key,hideitems) == false && det.key != 'img'">
                                    <span [innerHTML]="text.info[det.key][0]"></span>
                                </div>
                                <div class="col-6" *ngIf="helpers.searchArr(det.key,hideitems) == false && det.key != 'img'" style="text-align:right">
                                    <span [innerHTML]="text.info[det.key][1]" [ngClass]="det.key.indexOf('*') != -1 ? 'boldclass':''"></span>
                                </div>
                            </div>
                            <img *ngIf="text.info.img" src="assets/img/{{text.info.img}}" style="margin-bottom: 10px" />
                        </div>
                        <div class="contentsignup col-12" *ngIf="text.btnshow==1" [ngClass]="text.custom1 ? '' : 'btnbottom'">
                            <div *ngIf="!text.setdata" class="btn-three col-12 row" (click)="dataservice.willNavigate(text.btnlink)">
                                <div class="btn-leftcol col-12">{{text.btntext}}</div>
                                <div class="btn-rightcol"><img src="assets/img/right-arrow.png" /></div>
                            </div>
                        </div>
                        <!-- <div class="contentcustom col-12" *ngIf="text.custom1">
                            <span>{{text.custom1}}</span><br>
                            <span class="linkto" (click)="dataservice.willNavigate(customlink, null, {service: text.customoption});">{{text.custom2}}</span>
                        </div> -->
                    </div>
                </div>
            </div>

            <!-- element to display related topic to the previous topic -->
            <div *ngIf="contenttexts[key].layout=='relatedcolumns'" id="relatedcolumns{{key}}" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row">
                    <div class="col-12" class="divbreakpoint">
                        <h3>{{contenttexts[key].heading}}</h3>
                    </div>
                </div>
                <div class="form-group row">
                    <div *ngFor="let rel of contenttexts[key].topics" class="col secondoptions">
                        <img src="{{rel.imgtopic}}" />
                        <p class="optionstopic">{{rel.topic}}</p>
                        <p class="optionsdetail">{{rel.detail}}</p>
                        <p class="linkto" *ngIf="rel.link != ''">
                            <a href="{{rel.link}}" target="{{rel.target}}" *ngIf="rel.link.indexOf('https:')==0">{{rel.more}}</a>
                            <a *ngIf="rel.link.indexOf('https:') == -1" [routerLink]="rel.link">{{rel.more}}</a>
                        </p>
                    </div>
                </div>
            </div>

            <!-- element icons and header and texts -->
            <div class="iconstext" *ngIf="contenttexts[key].layout=='iconstext'" id="iconstext{{key}}" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row col-12">
                    <div class="col iconstextdiv" *ngFor="let col of contenttexts[key].cols">
                        <div class="icontext">
                            <img src="assets/img/{{col.icon}}" />
                            <h4>{{col.header}}</h4>
                        </div>
                    </div>
                </div>
                <div class="form-group row col-12">
                    <div class="col iconstextdiv" *ngFor="let col of contenttexts[key].cols">
                        <div *ngIf="col.text1">
                            <span [innerHTML]="col.text1"></span>
                        </div>
                        <div *ngIf="col.text2">
                            <span [innerHTML]="col.text2"></span>
                        </div>
                        <div *ngIf="col.text3">
                            <span [innerHTML]="col.text3"></span>
                        </div>
                    </div>
                </div>

            </div>

            <!-- element to display blocks in two columns -->
            <div class="blocks" *ngIf="contenttexts[key].layout=='blocks'" id="blocks{{key}}" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row" *ngIf="contenttexts[key].heading">
                    <h3>{{contenttexts[key].heading}}</h3>
                </div>
                <div class="form-group row" *ngFor="let l of lines[key]">
                    <div class="col"
                         [ngClass]="[contenttexts[key].borderblock == 1 ? 'borderblocks':'',contenttexts[key].type == 1 ? 'layout2col' : 'layoutimgcol']"
                         *ngFor="let text of l"
                    >
                        <div *ngIf="text">
                            <div class="col-12" *ngIf="text.topic != ''">
                                <label *ngIf="contenttexts[key].type == 1" class="topicblocks">{{text.topic}}</label>
                                <div class="divdash" *ngIf="contenttexts[key].type == 1 && contenttexts[key].borderblocks==0"></div>
                                <div class="headerdisrupt" *ngIf="contenttexts[key].type == 2">
                                    <label>{{text.topic | uppercase}}</label>
                                </div>
                                <div class="headerdisruptbg" *ngIf="contenttexts[key].type == 2"></div>
                            </div>
                            <div class="col-12" [ngClass]="contenttexts[key].type == 2 ? 'margintopblocks':''">
                                <img src="assets/img/{{text.img}}" *ngIf="contenttexts[key].type == 2 && text.img != ''" />
                                <div class="blocktext" style="padding: 10px 5px 5px 5px; ">
                                    <span  [innerHTML]="text.details"></span>
                                </div>
                                <ul *ngIf="text.listitems && text.type==1">
                                    <li *ngFor="let li of helpers.stringToArray(text.listitems,',')">{{li}}</li>
                                </ul>
                            </div>
                            <div class="col-12" *ngIf="contenttexts[key].type==1" style="margin-top: 20px;">
                                <div class="col-12 row" *ngIf="text.btnshow==1" (click)="dataservice.willNavigate(text.btnlink)">
                                    <div class="btn-leftcol2">{{text.btntext}}</div>
                                    <div class="btn-rightcol2"><img src="assets/img/right-arrow.png" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- element three columns -->
            <div class="threecolumns" *ngIf="contenttexts[key].layout=='threecolumns'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row" *ngIf="contenttexts[key].heading">
                    <h3 style="text-align:center; width: 100%">{{contenttexts[key].heading}}</h3>
                </div>
                <div class="row col-12" style="margin-bottom: 30px;">
                    <div class="col colbuble">
                        <p [innerHTML]="contenttexts[key].options[0].topic"></p>
                        <span [innerHTML]="contenttexts[key].options[0].description"></span>
                    </div>
                    <div class="col colbuble bublecolor2">
                        <p [innerHTML]="contenttexts[key].options[1].topic"></p>
                        <span [innerHTML]="contenttexts[key].options[1].description"></span>
                    </div>
                </div>
                <div class="row col-12">
                    <div class="col colbuble bublecolor2">
                        <p [innerHTML]="contenttexts[key].options[2].topic"></p>
                        <span [innerHTML]="contenttexts[key].options[2].description"></span>
                    </div>
                    <div class="col colbuble ">
                        <p [innerHTML]="contenttexts[key].options[3].topic"></p>
                        <span [innerHTML]="contenttexts[key].options[3].description"></span>
                    </div>
                </div>
            </div>

            <!-- element to display a static image -->
            <div class="form-group row staticimage" id="staticimage{{key}}" *ngIf="contenttexts[key].layout=='staticimage'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <img src="{{contenttexts[key].linkimg}}" title="{{contenttexts[key].title}}" alt="{{contenttexts[key].alt}}" />
            </div>

            <!-- element to display a simple window in a row with text -->
            <div *ngIf="contenttexts[key].layout=='window'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="form-group row" *ngFor="let option of contenttexts[key].options">
                    <div class="col-12">
                        <div class="contentwindow">
                            <h3 *ngIf="option.topic != ''">{{option.topic}}</h3>
                            <div [innerHTML]="option.details"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- element to display a list of items as bullet points, but displaying the items in individuals windows -->
            <div class="form-group row bulletblock" id="list{{key}}" *ngIf="contenttexts[key].layout=='list'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12">
                    <h3>{{contenttexts[key].heading}}</h3>
                    <ul>
                        <li *ngFor="let op of contenttexts[key].options" class="listoptions">
                            <h5 *ngIf="op.topic != ''">{{op.topic}}</h5>
                            <div><span>{{op.text}}</span></div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- element to display a list of items as bullet points replacing bullet points by arrow -->
            <div class="form-group row bulletblock" id="listtick{{key}}" *ngIf="contenttexts[key].layout=='listtick'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12">
                    <h3>{{contenttexts[key].heading}}</h3>
                    <ul>
                        <li *ngFor="let op of contenttexts[key].options" class="listoptionstick">
                            <div class="listtick" style="float:left;width: 70px; height:20px"><span class="tickicon">&#10004;</span></div>
                            <div class="listticktext"><span>{{op.text}}</span></div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- element to display a list of items as bullet points replacing bullet points by arrow -->
            <div class="form-group row bulletblock" id="listtickcolumns{{key}}" *ngIf="contenttexts[key].layout=='listtickcolumns'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12">
                    <h3>{{contenttexts[key].heading}}</h3>
                    <div class="row">
                        <div class="col" *ngFor="let opt of columns[key]">
                            <ul>
                                <li *ngFor="let op of opt" class="listoptionstick">
                                    <div class="listtick" style="float:left;width: 50px">
                                        <span class="tickicon">&#10004;</span>
                                    </div>
                                    <div class="listticktext"><span>{{op.text}}</span></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <!-- element to display texts in pills -->
            <div class="form-group row wordslist" id="worldcloud{{key}}" style="padding-top: 60px !important; padding-bottom: 60px !important;" *ngIf="contenttexts[key].layout=='wordcloud'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <h3>{{contenttexts[key].heading}}</h3>
                <div *ngFor="let w of contenttexts[key].words">
                    {{w}}
                </div>
            </div>

            <!-- element to display list of related links -->
            <div class="form-group row linksto" id="linksto{{key}}" *ngIf="contenttexts[key].layout=='linksto'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <h3>{{contenttexts[key].topic}}</h3>
                <div>
                    <ul>
                        <li *ngFor="let link of contenttexts[key].linksto">
                            <div style="float:left;width: 70px"><span class="markarrow">&#10148;</span></div>
                            <div style="float:left; width: calc(100% - 80px)"><a routerLink="/{{link.path}}">{{link.text}}</a></div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- element to display blocks of information with topic and texts separated in two columns and background in different color than the page background  -->
            <div class="cleanblock" id="cleanblocks{{key}}" *ngIf="contenttexts[key].layout=='cleanblocks'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12 headeroptions">
                    <h3>{{contenttexts[key].headingoptions | uppercase }}</h3>
                </div>
                <div class="form-group row">
                    <div class="col-6" *ngFor="let op of contenttexts[key].options | keyvalue" style="padding: 0px">
                        <div class="layout2col" [ngClass]="op.key % 2 == 0 ? 'leftblock' : 'rightblock'" style="border: 0px">
                            <div class="col-12 blocktopic">
                                <img *ngIf="contenttexts[key].options[op.key].img" alt="{{contenttexts[key].options[op.key].alt}}" src="assets/img/{{contenttexts[key].options[op.key].img}}">
                                <h4 class="topicblocks">{{contenttexts[key].options[op.key].topic}}</h4>
                            </div>
                            <div class="col-12 blocktext">
                                <span [innerHTML]="contenttexts[key].options[op.key].text"></span>
                                <ul *ngIf="contenttexts[key].options[op.key].listitems">
                                    <li *ngFor="let li of helpers.stringToArray(contenttexts[key].options[op.key].listitems,',')">{{li}}</li>
                                </ul>
                                <p class="linkto" *ngIf="contenttexts[key].options[op.key].link && contenttexts[key].options[op.key].link != ''">
                                    <a [routerLink]="contenttexts[key].options[op.key].link">
                                    {{contenttexts[key].options[op.key].more}}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cleanblock" id="cleanblocksli{{key}}" *ngIf="contenttexts[key].layout=='cleanblocksli'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12 headeroptions">
                <h3>{{contenttexts[key].heading | uppercase }}</h3>
                </div>
                <div class="form-group row">
                    <div class="col-6" *ngFor="let op of contenttexts[key].options | keyvalue" style="padding: 0px">
                        <div class="layout3col" [ngClass]="op.key % 2 == 0 ? 'leftblock' : 'rightblock'" style="border: 0px">
                            <div class="col-12 blocktopic">
                                <h4>{{contenttexts[key].options[op.key].topic}}</h4>
                                <p>{{contenttexts[key].options[op.key].text}}</p>
                            </div>
                            <div class="col-12 bulletblock">
                                <ul>
                                    <li *ngFor="let li of contenttexts[key].options[op.key].list">
                                        <div class="row">
                                            <div style="float: left; width: 50px">
                                                <span class="markarrow">&#10148;</span>
                                            </div>
                                            <div style="float: left; width: calc(100% - 70px)">
                                                <span>{{li.text}}</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row dividertext" id="dividertext{{key}}" *ngIf="contenttexts[key].layout=='dividertext'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="blockdivider">
                    <div class="col-12" [innerHTML]="contenttexts[key].heading"></div>
                </div>
            </div>

            <!-- element to display a text in a plain div with the background-color the same of menu -->
            <div class="form-group row" *ngIf="contenttexts[key].layout=='onlytext'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="textwindow" *ngFor="let opt of contenttexts[key].options | keyvalue" [innerHTML]="contenttexts[key].options[opt.key].text">
                </div>
            </div>

            <div class="form-group row" id="table{{key}}" *ngIf="contenttexts[key].layout=='table'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12">
                    <table class="table text-center">
                        <thead>
                        <tr *ngFor="let h of contenttexts[key].header">
                            <th *ngFor="let t of h.text | keyvalue" class="text-center" scope="col" [innerHTML]="h.text[t.key].text"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let line of contenttexts[key].options">
                                <th class="text-center" *ngFor="let text of line.text | keyvalue" [innerHTML]="line.text[text.key].text" [ngClass]="line.text[text.key].colorclass">
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <span>{{contenttexts[key].textinfo}}</span>
                </div>
            </div>

            <div class="form-group row listnumbers" id="listnumbers{{key}}" *ngIf="contenttexts[key].layout=='listnumbers'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12 headeroptions">
                    <h2>{{contenttexts[key].heading}}</h2>
                </div>
                <div class="col-12">
                    <ul>
                        <li *ngFor="let opt of contenttexts[key].options | keyvalue">
                            <div class="countlist">{{(1+helpers.NumberConverter(opt.key))}}.</div>
                            <div class="listtext" [innerHTML]="contenttexts[key].options[opt.key].text"></div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="form-group row calculator" *ngIf="contenttexts[key].layout=='calculator'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">

            </div>

            <div class="counter" *ngIf="contenttexts[key].layout=='counter'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col-12 headeroptions" *ngIf="counters.length > 0">
                    <h2>{{ contenttexts[key].title | uppercase}}</h2>
                </div>
                <div class="form-group row" *ngFor="let l of counters">
                    <div class="col layout2col" *ngFor="let col of l | keyvalue">
                        <div class="col-12 cleancol counterheader">
                            <h4>{{l[col.key].title | uppercase }}</h4>
                        </div>
                        <div class="col-12 cleancol">
                            <span
                                    style="font-size: 30px; font-weight: bold"
                                    [countUp]="l[col.key].value"
                                    [options]="coptions"
                                    [reanimateOnClick]="false">0
                            </span>
                            <span style="margin-left: 5px; margin-right: -25px" *ngIf="l[col.key].tag && l[col.key].tag != ''">{{l[col.key].tag}}</span>
                        </div>

                    </div>
                </div>
            </div>

            <!-- two columns details: one with header and icons, another only list items -->
            <div class="form-group row twocolumnsdetail" *ngIf="contenttexts[key].layout=='twocolumnsdetail'" [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''">
                <div class="col">
                    <h4 *ngIf="contenttexts[key].topic != ''">{{contenttexts[key].topic}}</h4>
                    <p *ngIf="contenttexts[key].heading!=''">{{contenttexts[key].heading}}</p>
                    <h4>{{contenttexts[key].options[0].topic}}</h4>
                    <p style="font-weight: bold; font-size: 12px" *ngIf="contenttexts[key].options[0].detail != ''">{{contenttexts[key].options[0].detail}}</p>
                    <ul class="detailleft">
                        <li *ngFor="let opt of contenttexts[key].options[0].options">
                            <div style="width: 100%" *ngIf="opt.img">
                                <img class="iconscolor" src="assets/img/{{opt.img}}" alt="{{opt.alt}}">
                            </div>
                            <p [innerHTML]="opt.text"></p>
                        </li>
                    </ul>
                </div>
                <div class="col detailright">
                    <h4>{{contenttexts[key].options[1].topic}}</h4>
                    <p *ngIf="contenttexts[key].options[1].detail != ''">{{contenttexts[key].options[1].detail}}</p>
                    <ul>
                        <li *ngFor="let opt of contenttexts[key].options[1].options">
                            <div style="float: left; width: 30px" *ngIf="opt.img">
                                <img src="assets/img/{{opt.img}}" alt="{{opt.alt}}">
                            </div>
                            <div style="float: left; width: calc(100% - 30px)">
                                <span>{{opt.text}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- big image on the left and details on the right -->
            <div class="form-group row bgimagedetails"
                 *ngIf="contenttexts[key].layout=='bgimagedetails'"
                 [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''"
            >
                <div class="col">
                    <div class="headercontet" style="margin-bottom: 20px;">
                        <h3 style="text-align: left">{{contenttexts[key].topic}}</h3>
                    </div>
                    <p [innerHTML]="contenttexts[key].text" style="font-weight:bold"></p>
                    <div class="divdescript">
                        <span [innerHTML]="contenttexts[key].description"></span>
                    </div>
                    <ul *ngIf="contenttexts[key].options" style="margin: 0px; padding: 0px">
                        <li *ngFor="let op of contenttexts[key].options" style="width: 100%" class="listoptionstick">
                            <div class="listtick" style="float:left;width: 50px; height:20px">
                                <span class="tickicon">&#10004;</span>
                            </div>
                            <div class="listticktext"><span>{{op.text}}</span></div>
                        </li>
                    </ul>
                </div>
                <div class="col imageright" *ngIf="!openmobile.open">
                    <div
                    *ngFor="let img of contenttexts[key].img" style="padding: 7px; object-fit: contain"
                    ngClass="img.shadow==0 ? 'noshadow':'addshadow'">
                      <img

                          src="assets/img/{{img.name}}"
                          title="{{img.title}}"
                          alt="{{img.alt}}"
                          (click)="openImage(img.popup,img.name)"
                      >
                      <br>
                      <span *ngIf="img.popup">click to open</span>
                      <a class="btn btn-primary" routerLink="/{{contenttexts[key].btnlink}}" *ngIf="contenttexts[key].btnlink">
                        {{contenttexts[key].btntext}}
                      </a>
                    </div>
                </div>
            </div>

            <!-- big image on the left with details on the right -->
            <div class="form-group row bgimagedetailsleft"
                 *ngIf="contenttexts[key].layout=='bgimagedetailsleft'"
                 [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''"
            >
              <div class="col imageright" *ngIf="!openmobile.open">
                  <div
                  *ngFor="let img of contenttexts[key].img"
                  ngClass="img.shadow==0 ? 'noshadow':'addshadow'"
                  style="padding: 7px">
                  <img

                      src="assets/img/{{img.name}}"
                      title="{{img.title}}"
                      alt="{{img.alt}}"
                      (click)="openImage(img.popup,img.name)"
                  >
                  <br>
                  <span *ngIf="img.popup">click to open</span>
                  </div>
              </div>
                <div class="col">
                    <div class="headercontet">
                    <h3 style="text-align: left">{{contenttexts[key].topic}}</h3>
                    </div>
                    <p [innerHTML]="contenttexts[key].text" style="font-weight:bold"></p>
                    <div class="divdescript">
                        <span [innerHTML]="contenttexts[key].description"></span>
                    </div>
                    <ul *ngIf="contenttexts[key].options" style="margin: 0px; padding: 0px">
                        <li *ngFor="let op of contenttexts[key].options" style="width: 100%" class="listoptionstick">
                            <div class="listtick" style="float:left;width: 50px">
                                <span class="tickicon">&#10004;</span>
                            </div>
                            <div class="listticktext"><span>{{op.text}}</span></div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- simple image in the center-->
            <div class="form-group row imagedisplay"
                 *ngIf="contenttexts[key].layout=='imagedisplay'"
                 [ngClass]="contenttexts[key].space == 1 ? 'divspace': ''"
            >
                <div class="col-12">
                    <div class="headercontet">
                        <h3 style="text-align: center">{{contenttexts[key].topic}}</h3>
                    </div>
                    <img src="assets/img/{{contenttexts[key].image}}" />
                </div>
            </div>
        </div>
    </div>
</div>
