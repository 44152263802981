<div id="topbar" (mouseenter)="getMouse($event)">
    <!-- login modal -->
    <div class="modal" tabindex="-1" role="dialog" id="loginmodal" style="margin-top: 110px">
        <div class="modal-dialog autoModal" role="document">
            <div class="modal-content">
                <form [formGroup]="FormLogin" #loginForm>
                    <div class="modal-header">
                        <h5 class="modal-title">Access Account</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <mat-form-field style="width: 100%">
                            <mat-label>User /Email</mat-label>

                            <input
                                    matInput [formControl]="emailFormControl"
                                    (change)="updateValue($event.target.value, 'logindata','user')"
                            />
                            <mat-hint>User is <strong>required</strong></mat-hint>
                            <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="emailFormControl.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>

                        </mat-form-field>
                        <mat-form-field style="width: 100%; margin-top: 15px">
                            <mat-label>Password</mat-label>
                            <input type="password"
                                   matInput [formControl]="passwordFormControl"
                                   (change)="updateValue($event.target.value,'logindata','password')"
                            />
                            <mat-hint>Password is <strong>required</strong></mat-hint>
                            <mat-error *ngIf="passwordFormControl.hasError('name') && !passwordFormControl.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="passwordFormControl.hasError('required')">
                                Password is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                        <div class="alert alert-warning" style="margin-top: 15px;float: left; width: 100%" *ngIf="errorlogin.status">{{errorlogin.msg}}</div>
                    </div>
                    <div class="modal-footer">
                        <form [formGroup]="reactiveForm" style="width: 100%;float:left !important;" *ngIf="reactiveForm">
                            <re-captcha style="float: left !important;"
                                        id="signuprecaptcha"
                                        formControlName="recaptchaReactive"
                            ></re-captcha>
                        </form>
                        <div style="width: 100%;float:left; text-align: left">
                            <button [disabled]="reactiveForm.invalid" style="margin-right: 10px" mat-raised-button color="primary" (click)="loginSystem()">
                                LOGIN
                                <div class="spinner-border text-light" role="status" *ngIf="loading">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <button type="button" mat-raised-button color="basic" data-dismiss="modal" (click)="decline()">CLOSE</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="divlogo">
            <a (click)="dataservice.willNavigate('/',1); closeMenu()" routerLink="/"><img name="logo" id="logo" width="150px" src="assets/img/logo.png"></a>
        </div>
        <div id="topbartext" class="divmenu">
            <ul>
                <li><a href="https://portal.vectordsitec.com.br" target="_blank">{{topbartexts['login'].text}}</a></li>
                <li>
                    <a routerLink="/contact" (click)="dataservice.willNavigate('contact')">
                        <img src="assets/img/{{topbartexts['contact'].image}}" title="{{topbartexts['contact'].title}}" alt="{{topbartexts['contact'].alt}}" >
                        {{topbartexts['contact'].text}}
                    </a>
                </li>
                <li style="padding-left: 0px" (click)="openLg()">{{selectedLg.short}}</li>
                <li style="margin-right: 5px" (click)="openLg()">
                    <button class="lgselected" data-toggle="tooltip" style="border:0px">
                        <img [src]="setClass(selectedLg.alias)">
                    </button>
                    <ng-template #tipContent tooltipClass="rc-tooltip-class">{{options}}</ng-template>
                </li>
                <li>
                    <div class="lgoptions" *ngIf="!openmobile && isadmin.admin == 0 || (isadmin.lg == 'en' && isadmin.admin==1)">
                        <div class="divflag" *ngFor="let lg of languages" (click)="setLanguage(lg.alias);">
                            <img [src]="setClass(lg.alias)">
                            {{lg.short}}
                        </div><br>
                    </div>
                </li>
            </ul>

        </div>
        <div id="topbarmob" class="divmenu" *ngIf="openmobile">
            <img src="assets/img/menu.png" (click)="openMenu($event.target)" class="topbar-mobilemenu" id="topbar-mobilemenu">
            <ul>
                <li style="padding-left: 0px" (click)="openLg()">{{selectedLg.short}}</li>
                <li style="padding-top: 0px; padding-bottom: 0px" (click)="openLg()">
                    <div class="lgselected" data-toggle="tooltip" style="border:0px">
                        <img [src]="setClass(selectedLg.alias)">
                    </div>
                    <ng-template #tipContent tooltipClass="rc-tooltip-class">{{options}}</ng-template>
                </li>
                <li>
                    <div class="lgoptions" style="width: 110px">
                        <div *ngFor="let lg of languages" style="width: 110px" (click)="setLanguage(lg.alias)">
                            <img style="height: auto; margin-top: -10px; float:left" [src]="setClass(lg.alias)">
                            {{lg.short}}
                        </div><br>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
