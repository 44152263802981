import {
  Component,
  OnInit,
  Input,
  HostListener,
  AfterViewInit,
  ViewChild,
  TemplateRef,
  EventEmitter, Inject, PLATFORM_ID, Optional
} from '@angular/core';
import { Helpers } from '../app.helpers';
import { DataService } from "../data.service";
import { Router } from '@angular/router';
declare var $: any;
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

// @ts-ignore
import * as lgicons from '../jsondata/languageicons.json';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ErrorMSG, LoginData} from "../app.models";
import {APP_BASE_HREF, isPlatformBrowser} from "@angular/common";
import {environment} from '../../environments/environment';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-topbar',
  templateUrl: './app-topbar.component.html',
  styleUrls: ['./app-topbar.component.scss']
})
export class AppTopbarComponent implements OnInit, AfterViewInit {
  executeAction: EventEmitter<any> = new EventEmitter();

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new UntypedFormControl('',[
    Validators.required,
    Validators.min(5)
  ])

  public reactiveForm: UntypedFormGroup = new UntypedFormGroup({
    recaptchaReactive: new UntypedFormControl(null, Validators.required)
  });

  public FormLogin: UntypedFormGroup;

  public languages: any = this.helpers.languages();
  private language = '';
  public lgicons: {} = lgicons.default;
  public topbartexts: any;
  public selectedLg = {alias:'',short: '',text:''};
  public options = '';
  public openmobile = false;
  public logindata: LoginData = this.helpers.setLoginData();
  public errorlogin: ErrorMSG = this.helpers.error();
  public loading = false;
  private link: any;
  public isadmin: any;
  private submenu: any;
  private structure: any;
  private adminsection: any;
  private pagefilename: any;
  private contentEN = {fields:[],texts:{}};

  @ViewChild('loginForm') loginForm;

  @Input() openlg: any;

  @HostListener("window:resize", ['$event'])
  public onResize() {
    this.detectScreenSize();
  }

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      private titleservice: Title,
      public helpers: Helpers,
      public dataservice: DataService,
      private router: Router
  ) {}

  ngOnInit(): void {
    this.dataservice.thislanguage.subscribe(lg => this.language = lg);
    this.selectedLg = this.languages.find(l => l.alias == this.language);
    this.dataservice.thissetadmin.subscribe(admin => this.isadmin = admin);
    this.dataservice.thistopbar.subscribe(texts => this.topbartexts = texts);
    this.FormLogin = new UntypedFormGroup({
      user: this.emailFormControl,
      password: this.passwordFormControl
    });
  }

  public getMouse(event) {
    this.dataservice.updateOptMenu('');
  }

  public ngAfterViewInit() {
    this.detectScreenSize();
    //console.log('component content started');
  }

  resetForm(template: TemplateRef<any>) {
    if (isPlatformBrowser(this.platformId)) {
      $("#loginmodal").modal('toggle');
    }
    this.FormLogin.reset();
    this.FormLogin.updateValueAndValidity();
  }

  public updateValue(event:any,array = '', field:string) {
    event = btoa(event);
    this[array][field] = event;
  }

  public loginSystem() {
    this.loading = true;
    if(this.logindata.user == '' || this.logindata.user == null || this.logindata.password == '' || this.logindata.password == null) {
      this.loading = false;
      this.errorlogin.status = true;
      this.errorlogin.msg = 'User and password must be filled';
    }
    else {
      let data = this.dataservice.encrypt(this.logindata);
      this.dataservice.requestLogin('portal',data).subscribe(response => {
        this.loading = false;
        if(response.code == 'OK') {
          this.errorlogin.status = true;
          this.errorlogin.msg = 'Login sucessfully';
          setTimeout(() => {
            if (isPlatformBrowser(this.platformId)) {
              $("#loginmodal").modal('toggle');
            }
          },2000);
        }
        else {
          this.errorlogin.status = true;
          this.errorlogin.msg = response.message;
        }
      }, error => {
        this.loading = false;
        this.errorlogin.status = true;
        this.errorlogin.msg = error.statusText;
      });
    }
  }

  private detectScreenSize() {
    if (isPlatformBrowser(this.platformId)) {
      const height = window.innerHeight;
      const width = window.innerWidth;
      this.openmobile = width < 961 ? true : false;
      this.dataservice.setMobile({open: this.openmobile,size: width});
    }
  }

  public setClass(lg) {
    lg = lg == 'en' ? 'au' : lg;
    if(lg == 'pt') {
      return "assets/img/flag_pt.png";
    }
    return "assets/img/flag_en.png";
    //return this.lgicons[lg];
  }

  public setLanguage(lg) {
    this.link = this.router.url.substring(1).split('/');
    if (isPlatformBrowser(this.platformId)) {
      $(".lgoptions").toggle();
    }
    sessionStorage.lg = lg;
    this.dataservice.setCurrency(this.helpers.currencyCountry(lg));
    this.dataservice.setLanguage(lg);
    this.selectedLg = this.languages.find(l => l.alias == lg);
    this.dataservice.updateTopBar(this.dataservice.getTexts('topbar','texts'));
    this.dataservice.updateMenu(this.dataservice.getTexts('menu','texts'));
    this.dataservice.updateSubMenu(this.dataservice.getTexts('menu','submenu'));
    this.dataservice.updateFooter(this.dataservice.getTexts('footer','texts'));
    this.dataservice.updateMiddleWindows(this.dataservice.getTexts('middlewindows','texts'),'index');
    const spliturl = this.router.url.substring(1).split('/');
    this.dataservice.thissubmenu.subscribe(submenu => this.submenu = submenu);

    if(spliturl[0] != 'admin') {
      const content = this.dataservice.getTexts(spliturl[0], 'texts', spliturl.length == 2 ? spliturl[1] : null);
      if(typeof content != 'undefined') {
        let data = this.dataservice.setContentData(content, spliturl);

        const subpage = this.link[1] ? this.link[1].split('#') : '';
        const pageref = this.link.length == 1 ? this.link[0] : subpage;
        const page = pageref.length == 1 ? pageref[0] : pageref[1];

        let lines = [];
        let c = 0;

        if (this.link.length > 1) {
          if(typeof content['pagetype'] != 'undefined' && content['pagetype'] == 1) {
            let c = 0;
            for(let pg in content['pages']) {
              for(let k in content['pages'][pg]) {
                if(content['pages'][pg][k].layout == 'blocks') {
                  lines[c] = this.dataservice.setLines(content['pages'][pg][k]);
                }
                c++;
              }
            }
          }
          else {
            if(this.link[0] != '') {
              if(content[page]) {
                for(let el of content[page]) {
                  if(typeof el.layout != 'undefined' && el.layout == 'blocks') {
                    lines[c] = this.dataservice.setLines(el);
                  }
                  c++;
                }
              }
            }
          }
        }
          
        this.dataservice.setLinesBlocks(lines);

        data['more'] = this.dataservice.getTexts('more','options');
        this.dataservice.updateContent(data);
        this.dataservice.setLink(spliturl);
        this.dataservice.thissubmenu.subscribe(menu => this.submenu = menu);
        this.dataservice.setLeftMenu(this.submenu,spliturl);
        if (spliturl[0] == '' || spliturl.length == 1) {
          if (typeof content['title'] != 'undefined' && content['title'] != '') {
            this.titleservice.setTitle(content['title']);
          } else {
            this.titleservice.setTitle(environment.title);
          }
        } else {

          if (spliturl.length > 1) {
            const menuopt = this.submenu[spliturl[0]];
            const subopt = menuopt.find(el => el.option == spliturl[1]);
            if (typeof subopt != 'undefined' && subopt.title != '') {
              this.titleservice.setTitle(subopt.title);
            } else {
              this.titleservice.setTitle(environment.title);
            }
          }
        }
      }
    }
    if(spliturl[0] == 'admin') {
      this.dataservice.thisadminsection.subscribe(section => this.adminsection = section);
      let component = '';
      let page = '';
      if(typeof this.adminsection['page'] != "undefined" && this.adminsection['page'] != null && this.adminsection['page'] != '') {
        component = this.adminsection['page'];
        page = this.adminsection['page'];
      }
      else {
        component = this.adminsection['component'];
      }
      this.getPageFile(this.adminsection['component'],this.adminsection['page']);
      const data = { filename: this.pagefilename, lg: this.language };
      this.dataservice.requestAPI('files',null,data).then(data => {
        let componentschema = {text:{}};
        if (data.code == 'OK') {
          componentschema = JSON.parse(data.data);
        } else {
          componentschema = require('../jsondata/en/' + this.pagefilename);
        }

        // get the texts from json file AU
        const keyfile = component == 'menu' ? 'submenu' : 'texts';
        let dataEN = {};
        const content = this.dataservice.getTexts(component, keyfile, page, 'en');
        for (let k in content) {
          dataEN[k] = content[k];
        }
        this.dataservice.setAdminContentEN(dataEN);
        this.dataservice.thiscontentadminen.subscribe(content => this.contentEN['texts'] = content);
        // get the texts from the current language

        if(this.contentEN.texts['pagetype'] == 0 || component == 'menu') {
          this.dataservice.updateAdminTexts(componentschema[keyfile]);
        }
        else {

        }

      });
    }
  }

  getPageFile(component,page = null) {
    if(page != null && page != '') {
      this.pagefilename = 'menucontent.' + page + '.help.json';
    }
    else {
      this.pagefilename = component + '.help.json';
    }
  }

  public openMenu(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      $(".lgoptions").hide();
      window.scroll(0, 0);
      let id = event.id.split('-');
      $("#" + id[1]).slideToggle("slow", this.checkMenu());

    }
  }

  public checkMenu() {
    if ($(this).is(':visible')) {
      $('body').css('overflow-x', 'hidden');
      $('body').css('overflow-y', 'hidden');
    } else {
      $('body').css('overflow-x', 'visible');
      $('body').css('overflow-y', 'visible');
    }
  }

  public openLg() {
    if (isPlatformBrowser(this.platformId)) {
      $(".lgoptions").toggle();
    }
  }

  decline(): void {
    if (isPlatformBrowser(this.platformId)) {
      $("#loginmodal").modal("toggle");
    }
    this.FormLogin.reset();
    this.FormLogin.updateValueAndValidity();
    this.errorlogin = this.helpers.error();
  }

  public closeMenu() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('test');
      $("#submenu").hide();
      if ($("#mobilemenu").is(':visible')) {
        $("#mobilemenu").slideToggle("slow");
      }
    }
  }

  public cleanError() {
    this.errorlogin = this.helpers.error();
  }

}
