import {Component, Input, OnInit} from '@angular/core';
import { DataService } from "../data.service";
import {Data} from "@angular/router";
import {Helpers} from "../app.helpers";
declare var $: any;


@Component({
  selector: 'app-middlewindows',
  templateUrl: './middlewindows.component.html',
  styleUrls: ['./middlewindows.component.scss']
})
export class MiddlewindowsComponent implements OnInit {
  public language: any;
  public windowtexts: object = {left:[],right:[]};

  @Input() link;

  constructor(
      public dataservice: DataService,
      public helpers: Helpers
  ) { }

  ngOnInit(): void {
    this.dataservice.updateMiddleWindows(this.dataservice.getTexts('middlewindows','texts'),this.link);
    this.dataservice.thismiddlewindows.subscribe(texts => this.windowtexts = texts);
  }

  public getMouse(event) {
    this.dataservice.updateOptMenu('');
  }
  //
  // setColors(event,bg,cl) {
  //   $("#"+event).css('background-color',bg);
  //   $("#"+event).css('color',cl);
  // }
}
