import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { apipaths } from './apipath';
declare var require: any;
import { DataService } from "./data.service";
import { environment } from '../environments/environment';

// @ts-ignore
import * as refpages from './jsondata/refpages.help.json';
import {count} from "rxjs/operators";

//const url = apipaths.login;
const headers = new HttpHeaders({'Content-Type': 'application/json'});

@Injectable({
  providedIn: 'root'
})

export class Helpers {
  constructor(

  ) { }

  checkType(txt) {
    return typeof txt;
  }

  // conver value to string
  public StringConverter = (value: any) => {
    if (value === null || value === undefined || typeof value === 'string') {
      return value;
    }

    return value.toString();
  }

  // convert value to  bolean
  public BooleanConverter = (value: any) => {
    if (value === null || value === undefined || typeof value === 'boolean') {
      return value;
    }

    return value.toString() === 'true';
  }

  //convert value to float number
  public NumberConverter = (value: any) => {
    if (value === null || value === undefined || typeof value === 'number') {
      return value;
    }

    return parseFloat(value.toString());
  }

  // error standard return or init
  public error() {
    return {status: false, msg: '', list: []};
  }

  public componentUpdate() {
    return { action: '', dataToUpdate: {} };
  }

  public textRegister(txt: string, action: string) {
    if ( action === 'INSERT') {
      return 'Novo item - ' + txt.toUpperCase();
    }
    if ( action === 'UPDATE') {
      return 'Update item - ' + txt.toUpperCase();
    }
  }

  // list of the languages available for translation in the website array[]
  public languages() {
    return [
      {alias: 'en', short: 'ENG', text: 'English', active: 1},
      {alias: 'pt', short: 'PT', text: 'Portugues', active: 1}
    ]
  }

  public sections() {
    return [
      {name: "index",text:"HOME"},
      {name: "footer",text:"FOOTER"},
      {name: "topbar",text:"TOP BAR"},
      {name: "menu",text:"MENU"},
      {name: "middlewindow",text:"MIDDLE WINDOW"},
      {name: "signup",text:"SIGN UP"},
      {name: "metadata",text: "META DATA"},
      {name: "termsconditions",text: "Terms & Conditions"},
      {name: "privacypolicy",text: "Privacy Policy"},
    ]
  }

  public arraySkip() {
    return ['menu','index','footer','metadata','signup','contact'];
  }

  // list of profiles available array[]
  public profiles() {
    return {
      1: 'ADMIN',
      2: 'WEB',
      3: 'MONITOR IN',
      4: 'MONITOR OUt',
      5: '3rd PARTY',
    };
  }

  // email verification function
  public validateEmail($email) {
    const emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    return emailReg.test( $email );
  }

  // signup help
  public signUp() {
    return {
      companyname: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      address3: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
      password: '',
      confirm: '',
      termconditions: false,
      customerid: 1
    }
  }

  //ask us email
  public email() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      subscribe: 0
    }

  }

  // login help
  public login() {
    return {
      user: '',
      password: ''
    }
  }

  // list item status return list[]
  public itemStatus() {
    return {
      0: 'Inactive',
      1: 'Active'
    };
  }

  // list item YESNO return list[]
  public itemYESNO() {
    return {
      0: 'NO',
      1: 'YES'
    };
  }

  /* get the JSON data from the jsondata folder
  * element - the component which the page is biding
  * key - the key variable to return object data
  * lg - the language selected
  * return - object
   */
  public getJSON(component = null, key = 'texts', lg = '',page = null, pagetype = null, path='./jsondata') {
    const chquery = page != null ? page.split('?') : null;
    if(chquery != null && chquery.length > 1) {
      page = chquery[0];
    }
    let content = '';
    const menu = require(path + '/' + lg + '/menu.help.json');
    switch (component) {
      case 'footer':
      case 'menu':
      case 'topbar':
      case 'linkbar':
      case 'middlewindows':
      case 'signup':
      case 'contact':
      case 'metadata':
      case 'keystructure':
      case 'more':
        content = require(path + '/' + lg + '/' + component + '.help.json');
        if(content == 'undefiend' || content == '' || typeof content[key] == 'undefined' || Object.keys(content[key]).length == 0) {
          content =   require(path + '/en/' + component + '.help.json');
        }
        return content[key];
      case 'index':
      case '/':
      case '':
        content = require(path + '/' + lg + '/index.help.json');
        if(content == 'undefiend' || content == '' || typeof content[key] == 'undefined' || Object.keys(content[key]).length == 0) {
          content =   require(path + '/en/' + component + '.help.json');
        }
        return content[key];
      case 'notfound':
        content = require(path + '/notfound.help.json');
        return content[key];
      case 'termsconditions':
        content = require(path + '/' + lg + '/menucontent.' + component + '.help.json');
        if(content == 'undefiend' || content == '' || typeof content[key] == 'undefined') {
          content =   require(path + '/en/menucontent.' + component + '.help.json');
        }
        if(page == component) {
          return content[key];
        }
        else {
          return content[key][component];
        }
      case 'privacypolicy':
        content = require(path + '/' + lg + '/menucontent.' + component + '.help.json');
        if(content == 'undefiend' || content == '' || typeof content[key] == 'undefined') {
          content =   require(path + '/en/menucontent.' + component + '.help.json');
        }
        if(page == component) {
          return content[key];
        }
        else {
          return content[key][component];
        }
      case 'cloudservers.virtualservers.customize':
        content = require(path + '/' + lg + '/menucontent.' + component + '.help.json');
        if(content == 'undefiend' || content == '' || typeof content[key] == 'undefined') {
          content =   require(path + '/en/menucontent.' + component + '.help.json');
        }
        if(page == component) {
          return content[key];
        }
        else {
          return content[key][component];
        }
      default:
        if(pagetype == 1) {
          return menu['fragment'][component];
        }
        else {
          if(menu['texts'][component]) {
            let findpage = menu['submenu'][component];
            if (page != '' && page != null && findpage != -1) {

              //check if the page contem subpage to router
              let subpages = [];
              const pagepath = page.split('/');

              // check queryparams and remove to get the JSON file
              for (let p of pagepath) {
                const queryparams = p.split('?');
                subpages.push(queryparams[0]);
              }
              content = subpages.length == 1 ? require(path + '/' + lg + '/menucontent.' + component + '.help.json') : require(path + '/' + lg + '/menucontent.' + component + '.' + subpages[0] + '.' + subpages[1] + '.help.json');

              if (content == 'undefiend' || content == '' || typeof content[key] == 'undefined' || Object.keys(content[key]).length == 0) {
                content = subpages.length == 1 ? require(path + '/en/menucontent.' + component + '.help.json') : require(path + '/au/menucontent.' + component + '.' + subpages[0] + '.' + subpages[1] + '.help.json');
              }

              if(page == component) {
                if (content[key]) {
                  return content[key];
                }
                else {
                  content = require(path + '/notfound.help.json');
                  return content[key];
                }
              }
              else {
                if (content[key]) {
                      return content[key];
                }
              }
            } else {
              content = require(path + '/notfound.help.json');
              return content[key];
            }
          }
        }
    }

  }

  public stringToArray(values: string, separator: string) {
    return values.split(separator);
  }

  public concatValues(values: any, separator: string, space: number) {
    let concat = '';
    for(let v in values) {
      let spaces = '';
      for(var i = 0; i < space; i++) {
        spaces += '&nbsp;';
      }
      concat += spaces+values[v]+spaces+separator;
    }

    return concat.slice(0, -1);
  }

  // function to serach value in object
  public search(data: any,value: string, field: string) {
    let search = {};
    if(Array.isArray(data)) {
      data.map(it => {
        if (value == it[field]) {
          search = it;
        }
      });
      return search;
    }
  }

  // function to search value in array
  public searchArr(value: string = '',obj = null) {
    if(obj != null) {
      if(obj.indexOf(value) != -1) {
        return true;
      }
    }
    return false;
  }

  // function to return size of object in view
  getSizeObject(obj) {
    return Object.keys(obj).length;
  }

  // get layout menu from json file array[]
  public getPages() {
    return refpages;
  }

  // hide fields to restrict changes
  public getReadOnly() {
    return [
        "bannerpath",
        "path",
        "projectimage",
        "layout",
        "color",
        "background",
        "background-color",
        "width",
        "height",
        "btnshow",
        "imgtopic",
        "pagetype",
        "alt",
        "image",
        "typeheader",
        "btnlink",
        "btnshow",
        "link",
        "ref",
        "fragment",
        "option",
        "customlink",
        "customoption",
        "class",
        "minsize",
        "position",
        "name",
        "required",
        "type",
        "typesize",
        "dcgroup",
        "cpu",
        "memory",
        "network",
        "os",
        "storage",
        "setdata",
        "space",
        "columns",
        "limit",
        "service",
        "borderblock",
        "lines",
        "id",
        "borderblocks",
        "linkimg",
        "field"
    ]
  }

  public getHideKey() {
    return [
        "columns",
        "lines",
        "layout",
        "space"
    ]
  }

  /* translate the website name services in menu to the portal api name services */
  public portalOptToSite() {
    return {
      "virtualservers": "SERVER_VIRTUAL",
      "baremetal": "SERVER_DEDICATED"
    }
  }

  /* chypher data */
  public cryptoData(data:{},form: string = null) {
    if(form != null) {
      const crypto = this.cryptoField(form);
      for (let k in data) {
        if (crypto.indexOf(k) != -1) {
          data[k] = btoa(data[k]);
        }
      }
    }
    return data;
  }

  private cryptoField(form: string) {
    const fields = {
      'signup' : [
          'password',
          'confirm',
          'email',
          'phone',
          'address1',
          'address2',
          'address3'
      ]
    }
    return fields[form];
  }

  // set the standard values for order
  public setOrder() {
    return {
      productopt: '',
      productcode: 0,
      dcid: 0,
      cpu: 0,
      memory: 0,
      network: 0,
      storage: 0,
      os: '',
      customerid: 0,
      servicecode: 0
    }
  }

  // set the location
  public setLocation() {
    return {
      name: 'Australia',
      stdname: 'AUD',
      id: 1
    }
  }

  public productService() {
    return {
      "cloudservers": {
        cpu: [],
        memory: [],
        storage: [],
        os: [],
        network: []
    },
      "baremetal": {
        cpu: [],
        memory: [],
        storage: [],
        os: [],
        network: []
      },
      "websites": {
        cpu: [],
        memory: [],
        storage: [],
        os: [],
        network: []
      }
    }
  }

  public currencies() {
    return ['AUD','USD','KGS','THB','MNT'];
  }

  public currencyCountry(country: string = '') {
    const currency = {
      'en': 'USD',
      'ru': 'USD',
      'mn': 'MNT',
      'th': 'THB',
      'pt': 'REAL'
    }
    return country != '' ? currency[country] : 'USD';
  }

  public domainToLg(lg = 'br') {
    console.log(lg);
    const opt = {'localhost:4000':'pt',br:'pt',com:'en',en:'en',ru:'ru',mn:'mn',pt:'pt',th:'th',kg:'ru'};
    const returnlg = opt[lg] ? opt[lg] : 'pt';
    return returnlg;
  }

  public dcCodeDomain(domain) {
    const dc = {'en':'AU','com': 'AU','kg': 'KG','mn': 'MN','www':'AU'};
    return dc[domain];
  }

  public getDCName(data, dcid) {
    let stdname = '';
    for(let c in data) {
      const dc = data[c].find(el => el.code == dcid);
      if(dc) {
        stdname = dc.dcname;
        break;
      }
    }
    return stdname;
  }

  public getUnit(hardware: string = '') {
    switch (hardware) {
      case 'cpu':
        return 'cores';
      case 'memory':
        return 'GB';
      case 'storage':
        return 'GB';
      case 'network':
        return 'Mbit/s';
      default:
        return ''
    }
  }

  // get to customize page
  public customizePages() {
    return [
        "virtualservers"
    ]
  }

  //Hide info for mobile
  public hideInfo(page) {
    const pages = {
      "cloudservers": ["img","cpu","memory","storage","network","network","os","support"]
    }
    return pages[page];
  }

  setLoginData() {
    return {
      user: '',
      password: ''
    }
  }

  checkFieldsAPI(table = null) {
    const fields = {
      "order":[
        {
          name: "productcode",
          required: 1,
          minsize: 1,
          minvalue: 1
        },
        {
          name: "cpu",
          required: 1,
          minsize: 1,
          minvalue: 1
        },
        {
          name: "memory",
          required: 1,
          minsize: 1,
          minvalue: 1
        },
        {
          name: "storage",
          required: 1,
          minsize: 1,
          minvalue: 1
        },
        {
          name: "network",
          required: 1,
          minsize: 1,
          minvalue: 1
        },
        {
          name: "os",
          required: 1,
          minsize: 1,
          minvalue: ""
        },
        {
          name: "dcid",
          required: 1,
          minsize: 1,
          minvalue: 1
        },
        {
          name: "cpu",
          required: 1,
          minsize: 1,
          minvalue: 1
        }
      ]
    }
    return fields[table];
  }

  pageKeys(page: string) {
    const keys = {
      "contact": {
        address: '',
        phone: '',
        email: '',
        suburb: '',
        state: '',
        contact:''
      },
      "cloudservers.virtualservers": {
        cpu: '',
        memory: '',
        storage: '',
        network: '',
        os: '',
        location: ''
      },
      "signup": {
        phone: '',
        email: '',
        suburb: '',
        state: '',
        contactname:'',
        companyname:'',
        country:'',
        postcode:'',
        address1:'',
      }
    }

    if(page) {
      return keys[page];
    }
  }

  pageMessages(page: string) {
    const messages = {
      contact: {
        required: '',
        validemail: '',
        validnumber: '',
        errorfiels:'',
        messageok:'',
        messagefault1: '',
        messagefault3:''
      },
      signup: {
        required: '',
        validemail: '',
        validnumber: '',
        errorfields:'',
        processingorder:'',
        processingaccount:'',
        termsconditions:''

      }
    }

    if (page) {
      return messages[page];
    }
  }
}
