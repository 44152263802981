import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import { DataService } from "./data.service";
import { ErrorMSG, UserData, NewUser } from './app.models';
import { Helpers } from './app.helpers';
import {Observable} from "rxjs";
import { map, catchError } from 'rxjs/operators';
import {environment} from "../environments/environment";

const headers = new HttpHeaders({'Content-Type': 'application/json'});

class Permissions {
    canActivate(listip: any): boolean {
        return true;
    }
}

@Injectable()

export class AccessGuard implements CanActivate {

    constructor(
        public router: Router,
        private dataService: DataService,
        private httpClient: HttpClient,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const url = route.url[0]['path'];
        if(url == 'login-admin' && environment.type == 'prod') {
            this.router.navigate(['./']);
        }
        const data = {IP:'localhost'};
        data.IP = sessionStorage.IP ? sessionStorage.IP : data.IP;
        console.log(data.IP);
        return this.checkAuthIP(data);
    }

    canAccess(route: ActivatedRouteSnapshot) {
        if(environment.type == 'prod') {
            this.router.navigate(['./']);
        }
        else {
            return true;
        }
    }

    checkAuthIP(data) {
        return this.dataService.requestLogin('admin',data, null, 0).pipe(
            map(data => {
                if(environment.type == 'prod') {
                    this.router.navigate(['./']);
                }
                else {
                    return true;
                }
            },
            error => {
                return false;
            })
        );
    }
}
