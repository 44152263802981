import {AfterViewInit, Component, Inject, OnInit, Optional, PLATFORM_ID, ViewChild} from '@angular/core';
import { Helpers } from '../app.helpers';
import { DataService } from "../data.service";
declare var $: any;
import {Data} from "@angular/router";
import {AppAdminComponent} from "../admin/app-admin/app-admin.component";
import {APP_BASE_HREF, isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit, AfterViewInit {
  public element = 'footer'
  public footertext: any;
  public emaildata = {name:'',email:'',message:'',subscription:0};
  public error = this.helpers.error();
  public display: any = { displayparent: true };
  public loading = false;
  public footer = 0;
  mobile: any = {open:false,size:0};

  @ViewChild(AppAdminComponent) child;

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      public helpers: Helpers,
      public dataservice: DataService
  ) {
    this.mobile = {open:false,size:0};
  }

  ngOnInit(): void {
    this.dataservice.thisfootertype.subscribe(type => this.footer = type);
    this.dataservice.thisfooter.subscribe(footer => this.footertext = footer);
    this.dataservice.thiscomponentdisplay.subscribe(display => this.display = display);
    this.dataservice.thisopenobile.subscribe(mob => this.mobile = mob);
    if(this.mobile.open) {
      $('#footersmall.col-2').css({'max-width':'33% !important','width': '33% !important','flex':'0 0 33% !important'});
      $('#footersmall.col-3').css({'max-width':'33% !important','width': '33% !important','flex':'0 0 33% !important'});
    }
  }

  ngAfterViewInit(): void {

  }

  public sendEmail() {
    if(this.emaildata.name.trim() != '' && this.emaildata.email.trim() != '' && this.emaildata.message.trim() != '' && this.emaildata.message.length > 5) {
      this.loading = true;
      this.dataservice.insertAPI('email', null, this.emaildata).subscribe((data) => {
        this.loading = false;
      }, error => {
        this.loading = false;
      })
    } else {
      if (isPlatformBrowser(this.platformId)) {
        if (this.emaildata.name.trim() == '') {
          $("#contatname").css('border', '1px solid red');
        }
        if (this.emaildata.email.trim() == '') {
          $("#contatemail").css('border', '1px solid red');
        }
        if (this.emaildata.message.trim() == '') {
          $("#contatmessage").css('border', '1px solid red');
        }
        this.error.status = true;
        this.error.msg = 'All fields must be filled. Please, try again';
      }
    }
  }

}
