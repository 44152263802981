import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppContentcustomizeComponent} from "../app-contentcustomize/app-contentcustomize.component";
import {AppSignupComponent} from "./app-signup.component";

const routes: Routes = [
    {
        path: '',
        component: AppSignupComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class SignupRoutingModule { }
