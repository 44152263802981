<div id="linkbar"
     class="linkbarmove"
     [ngClass]="[openmobile.open == true &&
     (this.linkref['link0'] != 'contact' || this.linkref.length > 2) ? 'fixbarmobile1': fixlinkbar == 1
     ? 'fixlinkbar': '',
     openmobile.open == true && (this.linkref['link0'] == 'contact' || this.linkref.length > 2) ? 'fixbarmobile2':'']"
     *ngIf="showlink==1;"
>
    <div class="form-row linkbartext">
        <div class="col" id="linkgoto">
            <div
                style="width: auto; float: left"
            >
            <div [ngClass]="openmobile.open == true ? '':'linktext'"><span style="margin-right:10px">&#10148;</span>{{linkref[0]['text']+'&nbsp;' | uppercase}}</div>
            </div>
        </div>
    </div>
</div>
