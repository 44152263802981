import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input, NgZone,
  OnInit,
  Output, PLATFORM_ID,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Helpers } from "../app.helpers";
import { DataService } from "../data.service";
import {ErrorMSG, LoginData, SignUp} from "../app.models";
import { Router } from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var $: any;
// @ts-ignore
import countries from "../jsondata/countries.json";
import {AppTopbarComponent} from "../app-topbar/app-topbar.component";
import {isPlatformBrowser} from "@angular/common";
import {take} from 'rxjs/operators';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-app-signup',
  templateUrl: './app-signup.component.html',
  styleUrls: ['./app-signup.component.scss']
})

export class AppSignupComponent implements OnInit, AfterViewInit {

  emailFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
  ]);
  nameFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.min(5),
  ]);
  phoneFormControl = new UntypedFormControl('', [
      Validators.required,
      Validators.min(8),
      Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
  ]);

  passwordFormControl = new UntypedFormControl('',[
    Validators.required,
    Validators.min(5)
  ])

  inputFormControl = new UntypedFormControl('',[
    Validators.min(5)
  ]);

  public reactiveForm: UntypedFormGroup = new UntypedFormGroup({
    recaptchaReactive: new UntypedFormControl(null, Validators.required)
  });

  public FormLogin: UntypedFormGroup;
  public linkmenu = ['signup'];
  public contenttexts: any;
  public signup: SignUp;
  public logindata: LoginData;
  public countries: any = countries;
  public error: ErrorMSG = this.helpers.error();
  public errorlogin: ErrorMSG = this.helpers.error();
  public loading = false;
  public finished = 0;

  // @ts-ignore
  @ViewChild(AppTopbarComponent) child: AppTopbarComponent;
  @Input() order: any;

  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      public helpers: Helpers,
      public dataservice: DataService,
      public router: Router,
      private _ngZone: NgZone
  ) { }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }

    this.logindata = this.helpers.setLoginData();
    this.signup = this.helpers.signUp();
    this.dataservice.thiscontent.subscribe(content => this.contenttexts = content);
    this.FormLogin = new UntypedFormGroup({
      user: this.emailFormControl,
      password: this.passwordFormControl
    })
    let display = {displayparent: true, displaylinkbar: false, textbanner: false, displaybanner: true, margintopfix: false};
    this.dataservice.updateDisplay(display);
  }

  ngAfterViewInit(): void {
    //console.log('component signup started');
  }

  public setChecked(checked: boolean) {
    this.signup.termconditions = checked;
  }

  public updateValue(event:any,array = '', field:string) {
    event = btoa(event);
    this[array][field] = event;
  }

  public signupSave() {
    this.loading = true;
    if(this.dataservice.checkFields(this.contenttexts.fields,this.signup) >= 1) {
      this.loading = false;
      this.error.status = true;
      this.error.msg = 'Required fields must be filled';
    }
    else {
      if(this.signup.termconditions) {
        this.loading = true;
        this.error.status = true;
        if (typeof this.order != 'undefined') {
          this.error.msg = 'Wait creating your new account and placing your order';
          let data = Object.assign(this.order,this.signup);
          this.dataservice.insertAPI('order', null, data).subscribe(response => {
            this.loading = false;
            this.error.msg = response.message;
            if(response.code == 'OK') {
              this.finished = 1;
            }
          },error => {
            this.error.msg = error.error;
          })
        }
        else {
          const data = this.signup;
          this.error.msg = 'Wait creating your new account';
          this.dataservice.insertAPI('signup', null, data).subscribe(response => {
            this.loading = false;
            if (response.code == 'OK') {
              this.finished = 1;
              this.error.msg = response.message;
            } else {
              this.error.status = true;
              this.error.msg = response.message;
            }
          }, error => {

          });
        }
      }
      else {
        this.loading = false;
        this.error.status = true;
        this.error.msg = 'You must accept term and conditions to place an order';
      }
    }
  }

}
