<app-linkbar [linkmenu]="linkmenu" *ngIf="!order"></app-linkbar>

<div id="contentsignup" class="container-fluid">
    <!--Header-->
    <div class="text-center" *ngIf="finished==0">
        <h3 class="pink-text mb-5"><strong>{{contenttexts.title}}</strong></h3>
    </div>
    <div class="divsignup" *ngIf="finished==0">
        <div class="form-group">
            <div *ngFor="let field of contenttexts.fields; index as i">
                <div
                        class="alert alert-light" *ngIf="field.name == 'password' || field.name == 'address1'">
                    <span *ngIf="field.name == 'address1'">Address Details</span>
                    <span *ngIf="field.name == 'password'">Account Password</span>
                </div>
                <mat-form-field
                    *ngIf="field.type=='text' || field.type=='password'"
                    [ngClass]="[field.position == 1  ? 'rc-left':'rc-right' , field.size == 0 ? 'half-width':'full-width']">

                    <mat-label *ngIf="field.name!='name'">{{field.text}}</mat-label>

                    <!-- email -->
                    <input type="{{field.type}}"
                           *ngIf="field.name=='email'"
                           matInput [formControl]="emailFormControl"
                           (change)="updateValue($event.target.value,'signup',field.name)"
                    />
                    <mat-hint *ngIf="field.required==1">{{field.text}} is <strong>required</strong></mat-hint>
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required') && field.name == 'email'">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required') && field.name == 'email' && field.required == 1">
                        Email is <strong>required</strong>
                    </mat-error>

                    <!-- name -->
                    <input type="{{field.type}}"
                           *ngIf="field.name=='name'"
                           matInput [formControl]="nameFormControl"
                           (change)="updateValue($event.target.value,'signup',field.name)"
                    />
                    <mat-error *ngIf="nameFormControl.hasError('name') && !nameFormControl.hasError('required') && field.name == 'name'">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="nameFormControl.hasError('required') && field.name == 'name' && field.required == 1">
                        Name is <strong>required</strong>
                    </mat-error>

                    <!-- phone -->
                    <input type="{{field.type}}"
                           *ngIf="field.name=='phone'"
                           matInput [formControl]="phoneFormControl"
                           (change)="updateValue($event.target.value,'signup',field.name)"
                    />
                    <mat-error *ngIf="phoneFormControl.hasError('phone') && !phoneFormControl.hasError('required') && field.name == 'phone'">
                        Please enter a valid phone numbeer
                    </mat-error>
                    <mat-error *ngIf="phoneFormControl.hasError('required') && field.name == 'phone' && field.required == 1">
                        Phone is <strong>required</strong>
                    </mat-error>

                    <!-- confirmpassword -->
                    <input type="{{field.type}}"
                           *ngIf="field.name=='confirm'"
                           matInput [formControl]="passwordFormControl"
                           (change)="updateValue($event.target.value,'signup',field.name)"
                    />
                    <mat-error *ngIf="passwordFormControl.hasError('confirm') && !passwordFormControl.hasError('required') && field.name == 'confirm'">
                        Please enter eight digits password
                    </mat-error>
                    <mat-error *ngIf="passwordFormControl.hasError('required') && field.name == 'confirm' && field.required == 1">
                        Password is <strong>required</strong>
                    </mat-error>

                    <!-- password -->
                    <input type="{{field.type}}"
                           *ngIf="field.name=='password'"
                           matInput [formControl]="passwordFormControl"
                           (change)="updateValue($event.target.value,'signup',field.name)"
                    />
                    <mat-error *ngIf="passwordFormControl.hasError('password') && !passwordFormControl.hasError('required') && field.name == 'password'">
                        Please enter eight digits password
                    </mat-error>
                    <mat-error *ngIf="passwordFormControl.hasError('required') && field.name == 'password' && field.required == 1">
                        Password is <strong>required</strong>
                    </mat-error>

                    <input
                            type="{{field.type}}"
                            *ngIf="field.name!='phone'
                            && field.name!='email'
                            && field.name!='name'
                            && field.name != 'password'
                            && field.name != 'confirm'"
                            matInput
                           [(ngModel)]="signup[field.name]">
                </mat-form-field>
                <mat-form-field *ngIf="field.type=='select'">
                    <mat-label>Country</mat-label>
                    <mat-select [(ngModel)]="signup.country">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let state of countries" [value]="state.code">{{state.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <form id="formrecaptcha" [formGroup]="reactiveForm" *ngIf="field.type=='checkbox'" style="width: 100%; float: left">
                    <div class="form-row">
                        <div class="col">
                            <mat-checkbox *ngIf="field.type=='checkbox'" class="signupcheckbox row"
                                          [checked]="signup.termconditions"
                                          (change)="setChecked($event.checked)">
                                {{field.text}}
                            </mat-checkbox>
                        </div>
                        <div class="col">
                            <re-captcha
                                    id="signuprecaptcha"
                                    formControlName="recaptchaReactive"
                            ></re-captcha>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12" style="float: left">
            <button [disabled]="reactiveForm.invalid" mat-raised-button color="primary" (click)="signupSave()">
                Sign Up
                <div class="spinner-border text-light" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
        </div>
        <div class="col-12" style="margin-top: 10px;float:left">
            <a style="color: dodgerblue; cursor: pointer" target="_blank" href="https://portal.vectordsitec.com">Login In</a>
        </div>
        <div class="alert alert-warning" style="margin-top: 15px;float: left; width: 100%" [innerHTML]="error.msg" *ngIf="error.status"></div>
    </div>
    <div class="divsignupinfo" *ngIf="finished==0">
        <h4>FAQ</h4>
        <h5>- What I need to signup and deploy a service?</h5>
        <p>You need to signup, inform the payment method and add the services to your account!</p>
    </div>
    <div id="endsignup" *ngIf="finished==1">
        <div class="generalmessage">
            <p>{{contenttexts.signupfinished.greatings}}</p>
            {{contenttexts.signupfinished.contact}}
        </div>
    </div>
</div>
