{{content.bannerpath1}}
<div class="container-fluid" id="bannercontent" style="padding-left: 0px; padding-right: 0px" *ngIf="display.displayparent && content.bannerpath1 && content.bannerpath2">
  {{content.bannerpath1}}
  <div class="row col-12" style="padding:0px; margin:0px">
    <div class="col" style="padding: 0px">
      <img class="bannercontent" src="assets/img/{{content.bannerpath1}}" id="bannerimg1" [ngClass]="display.displaylink == 0 ? '' : 'fixheightimg'" />
    </div>
    <div class="col" style="padding: 0px">
      <img class="bannercontent" src="assets/img/{{content.bannerpath2}}" id="bannerimg2" [ngClass]="display.displaylink == 0 ? '' : 'fixheightimg'" />
    </div>
  </div>
    
    <div class="bannertext" *ngIf="display.textbanner">
      <h4>{{content.bannertopic}}</h4>
      <div style="width:40%; padding:10px; float:left">
        <span [innerHTML]="content.bannertext"></span>
      </div>
      <div style="width:60%; padding:10px; float:left">
        <span [innerHTML]="content.bannertext2"></span>
      </div>
    </div>
    <div class="form-group row">
      <span class=bannertopic>{{bannertopic.topic}}</span>
    </div>
</div>
