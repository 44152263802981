import {Inject, Optional, PLATFORM_ID} from '@angular/core';
import {APP_BASE_HREF, isPlatformBrowser, isPlatformServer, ViewportScroller} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DataService } from "./data.service";
import {Data, Router, NavigationStart, NavigationEnd} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {environment} from "../environments/environment";
import { Subscription } from "rxjs";
import {REQUEST} from "@nguniversal/express-engine/tokens";
import {Helpers} from "./app.helpers";

declare var $: any;
declare var require: any;

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'rackcorpsite';
  private submenu:any;
  private menu: any;
  partner = '';
  public openlg = false;
  language = 'pt';
  subscription: Subscription;
  link = [];
  isBrowser = false;
  public setlines = {};

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      @Optional() @Inject(REQUEST) protected request: any,
      meta: Meta,
      private titleservice: Title,
      public dataservice: DataService,
      public helpers: Helpers,
      private httpClient: HttpClient,
      private router: Router,
      private viewportScroller: ViewportScroller
  )
  {

    if (isPlatformServer(this.platformId)) {
      this.language = 'pt';
      const host = this.request.get('host');
      console.log('host ===>>>>'+host);
      let lg = host.split('.');
      if(host && host.length > 1) {
        
        console.log('env ===>>'+environment.type);
        if (environment.type == 'dev' || environment.type == 'devsta') {
          let testlg = this.helpers.domainToLg(lg[lg.length - 1]);
          this.language = testlg != 'undefined' ? testlg : 'pt';
        }
        if(environment.type == 'sta') {
          console.log('language ===>>>'+this.language);
          this.language = typeof this.helpers.domainToLg(lg[lg.length - 1]) != 'undefined' ? this.helpers.domainToLg(lg[lg.length - 1]) : 'pt';
        }
        if(environment.type == 'prod') {
          if (lg[0] == 'www' && lg[lg.length - 1] == 'br') {
            this.language = 'pt'
          }
          else {
            this.language = typeof this.helpers.domainToLg(lg[lg.length - 1]) != 'undefined' ? this.helpers.domainToLg(lg[lg.length - 1]) : 'pt';
            console.log('language prod===>>>>'+this.helpers.domainToLg(lg[lg.length - 1]));
          }
        }
        this.dataservice.setCurrency(this.helpers.currencyCountry(this.language));
      }
    }
    console.log('language ===>>>'+this.language);

    const metadata = require('./jsondata/'+this.language+'/metadata.help.json')['texts'];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const path = event.url;
        this.link = path.substring(1).split('/');
      }
    });

    titleservice.setTitle(environment.title);
    for(let t of metadata['index']) {
      meta.updateTag(t,"name='"+t.name+"'");
    }
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      $(function () {
        $(document).on('scroll',function() {
          let element = $("#bannerimg1").offset();
          const getwidth = $(window).width();
          let percentage = '17%';
          if(element) {
            //let fromtop = (element.top -  $(window).scrollTop());

            //resizeMenu();
          }

          $(".floatmenu").show(300, function() {resizeMenu();});

          function resizeMenu() {
            if(element) {
              let fromtop = (element.top -  window.scrollY);

              if($(".bannerimg1").length) {
                $(".bannertopic").hide();
              }
              else {
                $(".bannertopic").show();
              }

              if(fromtop < -140) {
                $(".floatmenu").css({'position':'fixed','top':'135px'});
                $(".more").css({'position':'fixed','top':'320px'});
                $(".linktext").show();

                if(getwidth < 2500) {
                  percentage = '14.5%';
                }
                if(getwidth < 2050) {
                  percentage = '17%';
                }
                if(getwidth < 1700) {
                  percentage = '21.7%';
                }
                if(getwidth < 1350) {
                  percentage = '22%';
                }

                if(!$('#topbusinesstools').length) {
                  $(".floatmenu").width(percentage);
                  $("#linkbar").css({'position':'fixed','top':'95px'});
                }
              }
              else {
                if(!$('#topbusinesstools').length) {
                  $(".linktext").hide();
                  $(".floatmenu").css({'position':'absolute','top':'0px'});
                  $(".more").css({'position':'absolute','top':'180px'});
                  $(".floatmenu").width('100%');
                  $("#linkbar").css({'position':'initial','top':'0px'});
                }
              }
            }
          }
        })
      });
    }

    this.dataservice.updateDisplay({displayparent: true, displaylinkbar: false, textbanner: true, displaybanner: false, margintopfix: false});
    this.dataservice.updateOptMenu('');
    this.dataservice.setAdmin({'lg':'',admin:0});
    // this.httpClient.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
    //   if (isPlatformBrowser(this.platformId)) {
    //     sessionStorage.setItem('IP', res.ip);
    //   }
    // });
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        const path = event.url;
        this.link = path.substring(1).split('/');
        if(this.link.length > 2) {
          this.link[1] = this.link[1] + '/' + this.link[2];
          this.dataservice.updateDisplay({displayparent: true, displaylinkbar: false, textbanner: false, displaybanner: false, margintopfix: false});
        }

        let content = {};
        content = this.dataservice.getTexts(this.link[0],'texts',this.link[1]);
        const pagetype = this.dataservice.getTexts(this.link[0],'texts', null, this.language, 1);

        this.dataservice.setKeyStructure(this.dataservice.getTexts('keystructure','texts'));
        if(typeof content != 'undefined') {
          let data = {};
          data = this.dataservice.setContentData(content, this.link);
          data['more'] = this.dataservice.getTexts('more','options', null, this.language, 1);
          this.dataservice.updateContent(data);
          this.dataservice.setLink(this.link);
          this.dataservice.thismenu.subscribe(menu => this.menu = menu);
          this.dataservice.thissubmenu.subscribe(submenu => this.submenu = submenu);
          this.dataservice.setBarTopic({topic: this.menu[this.link[0]],heading: ''});

          this.dataservice.setLeftMenu(this.submenu, this.link);


          const subpage = this.link[1] ? this.link[1].split('#') : '';
          const pageref = this.link.length == 1 ? this.link[0] : subpage;
          const page = pageref.length == 1 ? pageref[0] : pageref[1];

          let lines = [];
          let c = 0;
          if(typeof content['pagetype'] != 'undefined' && content['pagetype'] == 1) {
            let c = 0;
            for(let pg in content['pages']) {
              for(let k in content['pages'][pg]) {
                if(content['pages'][pg][k].layout == 'blocks') {
                  lines[c] = this.dataservice.setLines(content['pages'][pg][k]);
                }
                c++;
              }
            }
          }
          else {
            if(this.link[0] != '') {
              if(content[page]) {
                for(let el of content[page]) {
                  if(typeof el.layout != 'undefined' && el.layout == 'blocks') {
                    lines[c] = this.dataservice.setLines(el);
                  }
                  c++;
                }
              }
            }
          }
          
          this.dataservice.setLinesBlocks(lines);

          this.dataservice.setLeftMenu(this.submenu,this.link);
          if (this.link[0] == '' || this.link.length == 1) {
            if (typeof content['title'] != 'undefined' && content['title'] != '') {
              this.titleservice.setTitle(content['title']);
            } else {
              this.titleservice.setTitle(environment.title);
            }
          } else {

            if (this.link.length > 1) {
              const menuopt = this.submenu[this.link[0]];
              const subopt = menuopt.find(el => el.option == this.link[1]);
              if (typeof subopt != 'undefined' && subopt.title != '') {
                this.titleservice.setTitle(subopt.title);
              } else {
                this.titleservice.setTitle(environment.title);
              }
            }
          }
        }
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      if(localStorage.lg) {
        const checklg = this.helpers.languages().find(el => el.alias == localStorage.lg);
        if(typeof checklg == 'undefined') {
          this.language = 'en';
          localStorage.lg = 'en';
        }
        else {
          this.language = localStorage.lg;
        }
      }
      else {
        if (window.location.hostname) {
          const getlang = window.location.hostname;
          let lg = getlang.split('.');
          if (environment.type == 'dev' || environment.type == 'devsta' || environment.type == 'sta') {
            let prefix = 'pt';
            localStorage.lg = localStorage.lg ? localStorage.lg : prefix;
            this.language = localStorage.lg ? localStorage.lg : prefix;
          }
          else if (environment.type == 'prod') {
            if (lg[0] == 'www' && lg[lg.length - 1] == 'br') {
              localStorage.lg = localStorage.lg ? localStorage.lg : 'pt';
              this.language = localStorage.lg ? localStorage.lg : 'pt';
            }
            else {
              localStorage.lg = localStorage.lg ? localStorage.lg : this.helpers.domainToLg()[lg[lg.length - 1]];
              this.language = localStorage.lg ? localStorage.lg : this.helpers.domainToLg()[lg[lg.length - 1]];
            }
          }
          else {
            localStorage.lg = localStorage.lg ? localStorage.lg : 'pt';
            this.language = localStorage.lg ? localStorage.lg : 'pt';
          }
        }
      }
    }
    if (isPlatformServer(this.platformId)) {
      this.language = 'en';
      const host = 'referer: ' + this.request.get('host');
      if(host) {
        let lg = host.split('.');
        if (environment.type == 'dev' || environment.type == 'devsta' || environment.type == 'sta') {
          this.language = 'pt';
        }
        else if(environment.type == 'prod') {
          if (lg[0] == 'www' && lg[lg.length - 1] == 'br') {
            this.language = 'pt'
          }
          else {
            this.language = typeof this.helpers.domainToLg()[lg[lg.length - 1]] != 'undefined' ? typeof this.helpers.domainToLg()[lg[lg.length - 1]] : 'pt';
          }
        }
      }
    }

    this.dataservice.setLanguage(this.language);
    this.dataservice.setFooter(0);
    //this.dataservice.setCurrency(this.helpers.currencyCountry(this.language));
    this.dataservice.setKeyStructure(this.dataservice.getTexts('keystructure','texts'));
    this.dataservice.updateTopBar(this.dataservice.getTexts('topbar','texts'));
    this.dataservice.updateMenu(this.dataservice.getTexts('menu','texts'));
    this.dataservice.updateSubMenu(this.dataservice.getTexts('menu','submenu'));
    this.dataservice.updateFooter(this.dataservice.getTexts('footer','texts'));
    this.dataservice.updateMiddleWindows(this.dataservice.getTexts('middlewindows','texts'),'index');
    this.dataservice.updateAdminTexts(this.dataservice.getTexts('index','texts',null,this.language));

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      // Client only code.
      $(function () {
        $("body").on('click', function (event) {
          var id = event.target.id;
          if (id.indexOf('menuitem-') == -1) {
            hideElements(id);
          }
        })

        $(document).on('mouseover', 'div', function () {
          const id = $(this).attr('id');
          const getclass = $(this).attr('class');

          if (id == 'linkbar'
              || id == 'topbar'
              || id == 'topbartext'
              || id == 'footer'
              || id == 'contentmenu'
              || id == 'contentsignup'
              || id == 'divcenter'
              || id == 'contentcustomize'
              || id == 'middlewindows'
              || id == 'banner') {
            $("#submenu").hide();
            $(".mainmenu li").removeClass('activemenu');
          }
        })
      })

      function hideElements(id = null) {

        $("#submenu").hide();
        $(".mainmenu li").removeClass('activemenu');
      }
    }

    if (isPlatformServer(this.platformId)) {
      // Server only code.
      console.log("Isnt browser: " + this.isBrowser);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
