import {AfterViewInit, Component, Inject, Input, OnInit, Optional, PLATFORM_ID} from '@angular/core';
import {Helpers} from "../app.helpers";
import {DataService} from "../data.service";
import {ActivatedRoute, Router} from "@angular/router";
declare var $: any;
import {Order, UserData, Location, ErrorMSG} from "../app.models";
declare var require: any;
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {APP_BASE_HREF, isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-app-contentcustomize',
  templateUrl: './app-contentcustomize.component.html',
  styleUrls: ['./app-contentcustomize.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out',
            style({ height: '*', overflow: 'hidden', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: '*', overflow: 'hidden', opacity: 1 }),
        animate('1s ease-in',
            style({ height: 0, opacity: 0 }))
      ]),
    ]),
  ]
})

export class AppContentcustomizeComponent implements OnInit, AfterViewInit {

  public thiscomponent = this.constructor.name;
  public childcomponent = this.constructor.name;
  public contenttexts: any;
  public submenu: any;
  public linkmenu = [];
  public menuopt = 'cloudservers';
  public url =  this.router.url.substring(1);
  public layout: number = 0;
  public page: number = 1;
  public vieworder = {title:'',btntxt:'',textprice:''};
  public order: Order = this.helpers.setOrder();
  public orderprice: number = 0.00;
  public title = 'test';
  public location: Location = this.helpers.setLocation();
  public error: ErrorMSG = this.helpers.error();
  public conerror: ErrorMSG = this.helpers.error();
  public dclist:any;
  public products:any;
  public currency:any = 'AUD';
  public currencies = this.helpers.currencies();
  public itemsorder = this.helpers.productService()[this.menuopt];
  public serviceopt = {};
  public showlink = 0;
  objectKeys = Object.keys;
  public hidekey = ['customerid','locationid','servicecode','productcode','dcid','productopt'];
  public messages: any = this.helpers.pageMessages('cloudservers.virtualservers');
  public structure: any;

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      public helpers: Helpers,
      public dataservice: DataService,
      private activerouter: ActivatedRoute,
      private router: Router
  ) { }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }

    const params = this.activerouter.snapshot.queryParams;
    this.linkmenu = this.router.url.substring(1).split('/');

    this.dataservice.updateDisplay({displayparent: true, displaybanner: true, margintopfix: true});


    this.menuopt = this.linkmenu[0];

    this.dataservice.thiscurrency.subscribe(currency => this.currency = currency);
    this.dataservice.thissubmenu.subscribe( submenu => this.submenu = submenu);
    this.dataservice.thiscontent.subscribe(content => this.contenttexts = content);
    this.dataservice.thiskeystructure.subscribe(structure => this.structure = structure);
    this.dataservice.setKeyPage(this.structure,this.messages,'cloudservers.virtualservers');
    this.dataservice.thiscomponentkeys.subscribe(componentkey => this.messages = componentkey['messages']);
    const serviceopt = this.contenttexts.hardware;
    const title = this.contenttexts.orderdetails;
    const btntdxt = this.contenttexts.editbtn;
    const textprice = this.contenttexts.textprice;
    this.vieworder.title = title;
    this.vieworder.btntxt = btntdxt;
    this.vieworder.textprice = textprice;
    this.serviceopt = serviceopt;
    this.dataservice.thiscomponentdcproducts.subscribe(dcs => {
      if(Object.keys(dcs).length > 0 && Object.keys(dcs['dclistct']).length > 0) {
        this.dclist = dcs['dclistct'];
      }
    });
    this.dataservice.thiscomponentproducts.subscribe(products => {
      if(typeof products['AU'] != "undefined") {
        this.itemsorder = products[this.menuopt];
      }
    });

    if(!this.dclist) {
      let service = [this.linkmenu[0],this.linkmenu[1]];
      if(params['service']) {
        service.push(params['service']);
        this.order['productopt'] = params['service'];
      }
      if(typeof params['order'] != 'undefined') {
        this.order = JSON.parse(params['order']);
        service.push(this.order['productopt']);
      }

      this.dataservice.getGroupProducts(service).then(data => {
        if(data['code'] === 'OK') {
          this.dclist = data['list']['dclistct'];
          if(typeof params['order'] != 'undefined') {
            this.dataservice.getProductsPrice(this.dclist,this.order,this.linkmenu).then(data => {
              this.itemsorder = data;
              this.getOrderPrice();
            })

          }
        }
        else {
          this.conerror.status = true;
          this.conerror.msg = data['error'];
        }
      })

    }

    if(typeof params['order'] != 'undefined' && this.itemsorder) {
      this.order = JSON.parse(params['order']);
      if(this.order.dcid != 0) {
        this.getProducts().then(data => {
          this.getOrderPrice(1);
        });
      }
    }

    this.layout = this.contenttexts.layout;
  }

  ngAfterViewInit() {
    //console.log('component customized started');
    if(this.order['dcid'] != 0) {
      this.nextOrder();
    }
  }

  getOrderPrice(orderdef: number = 0) {
    this.orderprice = 0.00;
    for(let item in this.helpers.productService()[this.menuopt]) {
      if(orderdef == 1 && this.order.cpu == 0 && this.itemsorder) {
        const finditem = this.itemsorder[item][0];
        if(typeof finditem != "undefined") {
          const price = finditem.price;
          this.order[item] = this.itemsorder[item][0].id;
          if (price != -1) {
            this.orderprice += +(parseFloat(price)).toFixed(2);
          }
        }
      }
      else {
        if (this.order[item] != 0 && this.itemsorder) {
          const finditem = this.itemsorder[item].find(el => el.id == this.order[item]);
          if(typeof finditem != "undefined") {
            const price = finditem.price;
            if (price != -1) {
              this.orderprice += +(parseFloat(price)).toFixed(2);
            }
          }
        }
      }
    }
  }

  async getProducts() {
    return await this.dataservice.getProductsPrice(this.dclist,this.order,this.linkmenu).then(data => {
        this.itemsorder = data;
        return data;
    });
  }

  nextOrder() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }

    this.page = 1;
    if(this.dataservice.checkFields(this.helpers.checkFieldsAPI('order'),this.order) >= 1) {
      this.error.status = true;
      this.error.msg = this.messages.erroroptions;
    }
    else {
      const content = this.dataservice.getTexts('signup','texts','');
      this.dataservice.updateContent(content);
      this.dataservice.thiscontent.subscribe(content => this.contenttexts = content);
      this.page = 2;
    }
  }

}
