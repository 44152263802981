<app-middlewindows [link]="link"></app-middlewindows>
<div id="divcenter" class="container-fluid" (mouseenter)="getMouse($event)">
    <div class="form-group row">
        <div id="indextopic" col="12">
            <p>{{indextexts.topic}}</p>
        </div>
    </div>
    <div class="form-group row" id="topbusinesstools">
            <div class="col indextoolscol" *ngFor="let opt of indextexts.businesstools; let i = index">        
                <div class="topic col-12">
                    <span style="font-size:26px">{{opt.text | uppercase}}</span>
                </div>
                <ng-container *ngFor="let op of menu[opt.ref]; let io = index">
                    <div class="col-12" *ngIf="opt.ref != 'consulting' || (opt.ref == 'consulting' && io != 3)">
                        <div class="toolsitems col">
                            <a routerLink="/{{op.link}}">
                                <div class="row" style="padding:0px; margin-left:0px !important">
                                    <div class="col-6">
                                        <img src="assets/img/{{op.image}}" style="float:left" />
                                    </div>
                                    <div class="col-6 textdiv">                 
                                        <span class="toolstext">{{op.subtext1}}</span>
                                    </div>
                                </div>
                                <div class="toolstextspan" [ngClass]="io == 2 ? 'noborder':''">
                                <span>{{op.topic | uppercase}}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
    </div>
    <div id="businesstoolssm" class="form-group row">
        <div class="toolsmob col" *ngFor="let opt of indextexts.businesstools">
            <div>
                <div style="width: 80%; text-align: center; margin: auto">
                    <img src="assets/img/{{menu[opt.ref][0].image}}" />
                </div>
            </div>
            <div class="col blockheader">{{opt.text | uppercase}}</div>
            <ul>
                <li *ngFor="let it of menu[opt.ref]"><a style="color: #666666" [routerLink]="it.link">{{it.topic | uppercase}}</a></li>
            </ul>
        </div>
    </div>
    <div class="form-group row" id="project">
        <div class="row">
            <div col="12" id="initialmessage">
                <p>{{indextexts.initialmessage1}}</p>
                <p>{{indextexts.initialmessage2}}</p>
            </div>
            <div class="col-12" style="margin-top: 40px">
                <img src="assets/img/{{indextexts?.project?.projectimage}}" />
            </div>
        </div>
    </div>
    <div id="skills" class="form-group row is-flex">
        <div class="col" *ngFor="let op of indextexts.windows" style="padding-bottom: 40px !important">
            <div class="card" style="width: 18rem;">
                <img src="assets/img/{{op.imgcard}}" class="card-img-top" alt="op.alt">
                <label>{{op.label}}</label>
                <div class="card-body">
                    <h5 class="card-title">{{op.topic}}</h5>
                    <p class="card-text" innerHTML="{{op.text}}"></p>
                    <a routerLink="{{op.btnlink}}" *ngIf="op.btnlink" class="btn btn-primary">{{op.btntext}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-contentmenu [injected]="injected" [injectedopt]="injectedopt"></app-contentmenu>

