import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AppLinkbarComponent} from "./app-linkbar.component";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
      AppLinkbarComponent
  ],
    imports: [
        CommonModule,
        RouterModule
    ],
  exports: [
      AppLinkbarComponent
  ]
})
export class AppLinkbarModule { };

export { AppLinkbarComponent }
