<head>
  <link href="favicon.ico" rel="shortcut icon" type="image/x-icon" />
  <link href="favicon.ico" rel="icon" type="image/x-icon" />
  <meta name="viewport" content="initial-scale=1, maximum-scale=1"/>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
  <title>{{ title }} {{ partner }}</title>
</head>
<app-topbar></app-topbar>
<app-menu></app-menu>
<app-banner></app-banner>
<router-outlet></router-outlet>
<app-footer></app-footer>
