import {AfterViewInit, Component, Inject, OnInit, Optional} from '@angular/core';
import { Helpers } from '../app.helpers';
import { DataService } from "../data.service";
import {APP_BASE_HREF} from "@angular/common";

@Component({
  selector: 'app-index',
  templateUrl: './app-index.component.html',
  styleUrls: ['./app-index.component.scss']
})
export class AppIndexComponent implements OnInit, AfterViewInit {
  public indextexts: any;
  public link: string = 'index';
  public menu: any;
  public lines = {0:0,1:1,2:2};
  public injected = 'index';
  public injectedopt = 'inject';

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      public helpers: Helpers,
      public dataservice: DataService
  ) { }

  ngOnInit(): void {
    this.dataservice.thiscontent.subscribe(content => this.indextexts = content);
    this.menu = this.dataservice.getTexts('menu','submenu');
    let display = {displayparent: true, displaylink: 0, displaylinkbar: false, textbanner: true, displaybanner: true, margintopfix: false};
    this.dataservice.updateDisplay(display);
  }

  ngAfterViewInit(): void {
    //console.log('component footer started');
  }

  getMenuOpt(opt: string = null) {
    let items = [];
    if(opt != null) {
      const options = this.menu[opt];
      for(let el of options) {
        items.push(el.topic);
      }
    }
    return items;
  }

  public getMouse(event) {
    this.dataservice.updateOptMenu('');
  }

}
