import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentMenuRoutingModule } from "./app-content-menu-routing.module";
import { AppContentcustomizeComponent } from "../app-contentcustomize/app-contentcustomize.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {AppLinkbarModule} from "../app-linkbar/app-linkbar.module";
import {AppSignupModule} from "../app-signup/app-signup.module";
import {AppSignupComponent} from "../app-signup/app-signup.component";


@NgModule({
  declarations: [
      AppContentcustomizeComponent
  ],
    imports: [
        CommonModule,
        ContentMenuRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        MatButtonModule,
        AppLinkbarModule,
        AppSignupModule
    ],
    exports: []
})
export class AppContentmenuModule { }
