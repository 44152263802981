import {AfterViewInit, Component, Inject, OnInit, Optional, PLATFORM_ID, ViewChild} from '@angular/core';
import { Helpers } from '../app.helpers';
import { DataService } from "../data.service";
import { FormsModule } from '@angular/forms';
declare var $: any;
import {AppAdminComponent} from "../admin/app-admin/app-admin.component";
import { trigger, state, style, animate, transition } from '@angular/animations';
import {APP_BASE_HREF, isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({
        'height': '*', 'opacity': '1'
      })),
      state('false', style({
        'height': '0', 'opacity': '0'
      })),
      transition('open <=> closed', [
        animate('1s ease-out')
      ]),
      transition('false => true', [
        animate('1s ease-in',
            style ({ opacity: '*' }))
      ]),
      transition('true => false', [
        animate('1s ease-in')
      ]),
    ]),
  ]
})
export class AppMenuComponent implements OnInit, AfterViewInit {
  public mainmenu: any = this.helpers.getPages()['default'];
  public menutexts: any;
  public topbartexts: any;
  public menuopt: string = '';
  public submenu: any = {};
  public submenu1: any = {};
  public submenu2: any = {};
  public activemenu = '';
  public openmenu = false;
  public display: any;

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      public helpers: Helpers,
      public dataservice: DataService
  ) { }

  ngOnInit(): void {
    this.dataservice.thismenu.subscribe(menu => this.menutexts = menu);
    this.dataservice.thissubmenu.subscribe(submenu => this.submenu = submenu);
    this.dataservice.thistopbar.subscribe(topbar => this.topbartexts = topbar);
    this.dataservice.thiscomponentdisplay.subscribe(display => this.display = display);
    this.dataservice.thisoptmenu.subscribe(opt => this.activemenu = opt);

    if (isPlatformBrowser(this.platformId)) {
      $(document).on('mouseenter', '.mainmenu', function () {
        $("#submenu").show(300);
      }).on('mouseleve', '.mainmenu', function () {
        $("#submenu").hide(300);
      })

      $(document).on('click', '.mobilemenu', function (event) {
        event.preventDefault();
        let id = $(this).attr('id').split('-');
        if ($("#ul-" + id[1]).is(':visible')) {
          $("#ul-" + id[1]).hide();
          $("[id^='img-']").attr('src', 'assets/img/down-arrow.png');
        } else {
          $("[id^='ul-']").hide();
          $("[id^='img-']").attr('src', 'assets/img/down-arrow.png');
          $("#ul-" + id[1]).show();
          $("#img-" + id[1]).attr('src', 'assets/img/up-arrow.png');
        }
      })
    }
  }

  ngAfterViewInit(): void {
    //console.log('component menu started');
  }

  actionHover(event) {
    const submenu = event.id;
  }

  public getMouse(event) {
    if (isPlatformBrowser(this.platformId)) {
      $(".lgoptions").hide();
    }
    this.menuopt = event;
    this.submenu1 = {};
    this.submenu2 = {};
    if(this.activemenu == this.menuopt) {
      this.activemenu;
    }
    this.dataservice.updateOptMenu(this.menuopt);
    this.dataservice.thisoptmenu.subscribe(optmenu => this.activemenu = optmenu);
    const limit = 3;
    let count = 0;
    for (let i in this.submenu[this.menuopt]) {
      if (count < limit) {
        this.submenu1[i] = this.submenu[this.menuopt][i];
        count++;
      } else {
        this.submenu2[i] = this.submenu[this.menuopt][i];
      }
    }
  }

  public inactiveMenu(event) {
    let menuid = event.id;
    console.log(this.menutexts);
    if(typeof this.menutexts[event.id] == 'undefined') {
      this.activemenu = '';
    }
  }

  public closeMenu() {
    this.activemenu = '';
    if (isPlatformBrowser(this.platformId)) {
      $("#submenu").hide();
      if ($("#mobilemenu").is(':visible')) {
        $("#mobilemenu").slideToggle("slow");
      }
    }
  }

}
