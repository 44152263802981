import {Component, OnInit, Input, AfterViewInit, Optional, Inject, PLATFORM_ID} from '@angular/core';
import { DataService } from "../data.service";
import { Helpers } from "../app.helpers";
import {Data} from "@angular/router";
import {APP_BASE_HREF, isPlatformBrowser} from "@angular/common";
declare var $: any;

@Component({
  selector: 'app-linkbar',
  templateUrl: './app-linkbar.component.html',
  styleUrls: ['./app-linkbar.component.scss']
})
export class AppLinkbarComponent implements OnInit, AfterViewInit {
  public links: any = {};
  public submenu: any;
  public menu: any;
  public openmobile: any;
  public fixlinkbar = 0;
  public linkref: any;
  private content: any;
  clink = 0;
  @Input() linkmenu: any;
  @Input() showlink: any;
  @Input() childcomponent: any;

  constructor(
      @Optional() @Inject(APP_BASE_HREF) private basehref: string,
      @Inject(PLATFORM_ID) private platformId: Object,
      public helpers: Helpers,
      public dataservice: DataService
  ) { }

  ngOnInit(): void {
    this.dataservice.thislink.subscribe(link => this.linkref = link);
    this.clink = this.linkmenu.length;
    this.dataservice.thismenu.subscribe(menu => this.menu = menu);
    this.dataservice.thissubmenu.subscribe(submenu => this.submenu = submenu);
    this.dataservice.thisopenobile.subscribe(mobile => this.openmobile = mobile);
    this.dataservice.thiscontent.subscribe(content => this.content = content);

    let c = 1;

    this.fixlinkbar = this.fixLinkPosition(this.childcomponent);
  }

  // function to fix the position of linkbar as it is fixed
  fixLinkPosition(component: string = '') {
    const fixposition = ['AppContentcustomizeComponent'];
    let fix = 0;
    if(component != '' && fixposition.indexOf(component) != -1) {
      fix = 1;
    }
    return fix;
  }

  ngAfterViewInit(): void {
    //console.log('component linkbar started');
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(function () {
        var pageURL = $(location).attr("href");
        if (pageURL.indexOf('customize') != -1) {
          $("#linkbar").css('margin-top', '-55px !important');
        }
      });
    }

  }

  convertLink(link) {
    return link.join('/');
  }
}
