<app-linkbar [linkmenu]="linkmenu"></app-linkbar>
<div id="contentsignup" class="container-fluid">
    <!--Header-->
    <div class="text-center">
        <h3 class="pink-text mb-5"><strong>{{contenttexts.title}}</strong></h3>
    </div>
    <div id="maincontact" style="width: 100%; float: left">
    <div id="contact">
        <h6 style="font-size: 18px !important;">{{contenttexts.title2}}</h6>
        <div class="form-group" style="padding: 10px">
            <div *ngFor="let field of contenttexts.fields; index as i">
                <mat-form-field *ngIf="field.type=='text' || field.type=='password'">
                    <mat-label>{{field.text}}</mat-label>
                    <input *ngIf="field.name=='name'"
                            matInput [formControl]="nameFormControl"
                    />
                    <mat-hint *ngIf="field.name=='name' && field.required==1">Name is <strong>required</strong></mat-hint>
                    <mat-error *ngIf="nameFormControl.hasError('required') && field.name=='name'">
                        Name is <strong>required</strong>
                    </mat-error>

                    <input *ngIf="field.name=='companyname'"
                           matInput [formControl]="companyFormControl"
                    />
                    <mat-hint *ngIf="field.name=='companyname' && field.required==1">Name is <strong>required</strong></mat-hint>
                    <mat-error *ngIf="companyFormControl.hasError('required') && field.name=='companyname'">
                        Name is <strong>required</strong>
                    </mat-error>

                    <input class="phone" *ngIf="field.name=='phone'"
                            matInput [formControl]="phoneFormControl"
                    />
                    <mat-error *ngIf="phoneFormControl.hasError('phone') && field.name=='phone'">
                        Please enter a valid phone number
                    </mat-error>

                    <input *ngIf="field.name=='subject'"
                            matInput [formControl]="subjectFormControl"
                    />
                    <mat-error *ngIf="subjectFormControl.hasError('subject') && field.name=='subject'">
                        Please enter a valid phone number
                    </mat-error>

                    <input *ngIf="field.name=='email'"
                            matInput [formControl]="emailFormControl"
                    />
                    <mat-hint *ngIf="field.name=='email' && field.required==1">Email is <strong>required</strong></mat-hint>
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required') && field.name=='email'">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required') && field.name=='email'">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field><br>
            </div>

            <mat-form-field [style.fontSize]="16">
                <mat-label>Message</mat-label>
                <textarea matInput
                height="200px"
                          cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="7"
                          [formControl]="inputFormControl"
                          style="font-size: 14px !important;"
                ></textarea>
            </mat-form-field>
            <form [formGroup]="reactiveForm" style="float: left; width: 100%">
                <re-captcha style="float: left; width: 100%; margin-bottom: 15px" formControlName="recaptchaReactive"></re-captcha>
                <div class="col-12" style="padding: 0px;float:left">
                    <button [disabled]="reactiveForm.invalid" mat-raised-button color="primary" (click)="sendEmail()">
                        Send
                        <div class="spinner-border text-light" role="status" *ngIf="loading">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                </div>
            </form>
            <div class="alert alert-message" style="margin-top: 20px; float: left; width: 100%;font-weight: bold; font-size:18px" *ngIf="error.status" [innerHTML]="error.msg"></div>
        </div>
    </div>
    <div id="contactinfo">
        <div class="form-row">
            <div class="col">
                <h6 style="font-size: 18px !important;">: : {{contenttexts.locationinfo.title}}</h6>
                <h6><span>Address:</span> {{contenttexts.locationinfo.address}}</h6>
                <h6><span>Suburb:</span> {{contenttexts.locationinfo.suburb}}</h6>
                <h6><span>State:</span> {{contenttexts.locationinfo.state}}</h6>
                <h6><span>Phone:</span> {{contenttexts.locationinfo.phone}}</h6>
            </div>
            <div class="col">
                <h6 class="pink-text" style="font-size: 18px !important;">: : {{contenttexts.salesinfo.title}}</h6>
                <h6><span>Contact:</span> {{contenttexts.salesinfo.contactname}}</h6>
                <h6><span>Phone:</span> {{contenttexts.salesinfo.phone}}</h6>
                <h6><span>Email:</span> {{contenttexts.salesinfo.email}}</h6>
            </div>
        </div>
        <div style="float: left; width: 100%">
            <iframe
                    width="100%"
                    height="450"
                    frameborder="0" style="border:0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1828.5502924629566!2d-46.654059797441626!3d-23.56483069154537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce59c7c441f8df%3A0x451f6f24e3b13771!2sAv.%20Paulista%2C%201079%20-%20Bela%20Vista%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001311-200%2C%20Brasil!5e0!3m2!1spt-BR!2sau!4v1625104868438!5m2!1spt-BR!2sau" allowfullscreen>
            </iframe>
        </div>
    </div>
    </div>
</div>
