import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SignupRoutingModule} from "./app-signup-routing.module";
import {AppSignupComponent} from "./app-signup.component";
import {AppLinkbarModule} from "../app-linkbar/app-linkbar.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatSelectModule} from "@angular/material/select";
import {RecaptchaFormsModule, RecaptchaModule, RecaptchaV3Module} from "ng-recaptcha";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatChipsModule} from "@angular/material/chips";

@NgModule({
  imports: [
    CommonModule,
    SignupRoutingModule,
    AppLinkbarModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  exports: [
    AppSignupComponent
  ],
  declarations: [
    AppSignupComponent
  ]
})
export class AppSignupModule { }
