<div id="middlewindows" (mouseenter)="getMouse($event)">
    <div class="form-group row">
        <div class="middleleft col-6 row" [ngStyle]="{'background-color':windowtexts['left'][0].background}">
            <div class="col-6" *ngFor="let col of windowtexts['left'] | keyvalue" id="{{'col'+col.key}}"
                 [ngStyle]="{'background-color':windowtexts['left'][col.key].background,'color':windowtexts['left'][col.key].color}"
                 [routerLink]="windowtexts['left'][col.key].link">
                <div style="width: 100%; margin-bottom: 15px">
                <img
                        src="assets/img/{{windowtexts['left'][col.key].image}}"
                        title="{{windowtexts['left'][col.key].title}}"
                        alt="{{windowtexts['left'][col.key].alt}}"
                        >
                </div>
                <p>
                    <span class="firsttext">
                    {{windowtexts['left'][col.key].firstline}} <br>
                    {{windowtexts['left'][col.key].secondline}}
                    </span>
                </p>
                <span class="secondtext"> {{windowtexts['left'][col.key].thirdline}}</span>
            </div>
        </div>
        <div class="middleright col-6 row" [ngStyle]="{'background-color':windowtexts['left'][1].background}" >
            <div class="col-6" *ngFor="let col of windowtexts['right'] | keyvalue" id="{{'col'+col.key}}"
                 [ngStyle]="{'background-color':windowtexts['right'][col.key].background,'color':windowtexts['right'][col.key].color}"
                 [routerLink]="windowtexts['right'][col.key].link"
            >
                <div style="width: 100%; margin-bottom: 15px">
                    <img
                            src="assets/img/{{windowtexts['right'][col.key].image}}"
                            title="{{windowtexts['right'][col.key].title}}"
                            alt="{{windowtexts['right'][col.key].alt}}"
                            >
                </div>
                <p>
                <span class="firsttext">
                    {{windowtexts['right'][col.key].firstline}} <br>
                    {{windowtexts['right'][col.key].secondline}}
                </span>
                </p>
                <span class="secondtext"> {{windowtexts['right'][col.key].thirdline}}</span>
            </div>
        </div>
    </div>
</div>
