import { DataService } from './data.service';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {
    RECAPTCHA_SETTINGS,
    RECAPTCHA_V3_SITE_KEY,
    RecaptchaFormsModule,
    RecaptchaModule, RecaptchaSettings,
    RecaptchaV3Module
} from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppTopbarComponent } from './app-topbar/app-topbar.component';
import { AppLinkbarComponent } from './app-linkbar/app-linkbar.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppContentmenuComponent } from './app-contentmenu/app-contentmenu.component';
import { AppIndexComponent } from './app-index/app-index.component';
import { AppBannerComponent } from './app-banner/app-banner.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MiddlewindowsComponent } from './middlewindows/middlewindows.component';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AppSignupComponent } from './app-signup/app-signup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDividerModule} from '@angular/material/divider';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
//import {MatChipsModule} from "@angular/material/chips";
import {AppAdminComponent, RemoveWhiteSpacePipe} from './admin/app-admin/app-admin.component';
import {AccessGuard} from "./accessguard";
import { AppAdminloginComponent } from './admin/app-adminlogin/app-adminlogin.component';
import { AppNotauthorizedComponent } from './admin/app-notauthorized/app-notauthorized.component';
import { AppContactComponent } from './app-contact/app-contact.component';
import {RouterModule} from "@angular/router";
import {AppContentmenuModule} from "./app-contentmenu/app-contentmenu.module";
import {AppLinkbarModule} from "./app-linkbar/app-linkbar.module";
import {AppSignupModule} from "./app-signup/app-signup.module";
import { ModalModule } from 'ngx-bootstrap/modal';
import {CountUpModule} from 'ngx-countup';

@NgModule({
    declarations: [
        AppComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppIndexComponent,
        AppBannerComponent,
        AppMenuComponent,
        MiddlewindowsComponent,
        AppAdminComponent,
        AppAdminloginComponent,
        AppNotauthorizedComponent,
        AppContactComponent,
        AppContentmenuComponent,
        RemoveWhiteSpacePipe
    ],
    imports: [
        BrowserModule.withServerTransition({appId: 'vectorsite'}),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FontAwesomeModule,
        FormsModule,
        NgbModule,
        NgbPaginationModule,
        NgbAlertModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatSelectModule,
        MatCardModule,
        MatListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        RecaptchaV3Module,
        RecaptchaFormsModule,
        RecaptchaModule,
        AppContentmenuModule,
        AppLinkbarModule,
        BrowserTransferStateModule,
        TransferHttpCacheModule,
        CountUpModule,
        ModalModule.forRoot()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        DataService,
        AccessGuard,
        HttpClientModule,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
            } as RecaptchaSettings,
        }
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
